import {  GET_SELLER_DELIVERIES, GET_SELLER_DELIVERY_ANALYTICS } from '../config/endpoints';
import api from '../config/setAuthHeader';

export const getSellerDeliveries = async ({ queryKey }: any) => {
  const [_, payload] = queryKey;
  const { page, size } = payload;
  const { data } = await api.get(`${GET_SELLER_DELIVERIES}?page=${page}&pageSize=${size}`);
  return data;
};

export const getSellerDeliveriesAnalytics = async () => {
  const { data } = await api.get(`${GET_SELLER_DELIVERY_ANALYTICS}`);
  return data;
};
