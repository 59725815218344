import { styled } from '@mui/material/styles';
import { COLORS } from '../../../theme/color';

export const NavCon = styled('div')`
  background-color: ${COLORS.White};
  left: 0;
  padding: 1rem 0;
  width: 234px !important;
  height: 100vh;
  overflow: hidden;
  top: 0.5rem;
  .menu {
    padding: 3rem 1.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 20vh;
    h2 {
      font-weight: 600;
      font-size: 20px;
    }
  }

  .nav_bar {
    height: 80vh;
    overflow: auto;
  }

  .logout {
    padding: 3rem 1.5rem;
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: ${COLORS.White};
    transition: background-color 0.3s ease-in-out;
    p {
      font-weight: 400;
      font-size: 16px;
      text-decoration: none;
      color: ${COLORS.MainRed};
    }
    margin-bottom: 1rem;
    text-transform: capitalize;
    cursor: pointer;
    &:hover {
      color: ${COLORS.MainBlue};
      font-weight: 500;
    }
  }
`;
