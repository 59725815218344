import { ChangeEvent } from 'react';
import { DashboardHeader, TabPanel, TopHeaderCard } from '../../components/molecules';
import { topCardData, loanRequestColumn, approvedLoanColumn, disbursedColumn, loans, filter } from './data';
import { CustomAuthTab, CustomAuthTabs } from './style';
import { useState } from 'react';
import { TableCON, TableHeaderCON } from './style';
import { AdminSettingsIcon } from '../../assets/Icons';
import { FILTER_KEYS } from '../../store/keys';
import { Container, CustomTable, SelectField, Spacer } from '../../components/atoms';
import { loanFilter } from '../../store/atoms/loanAtom';
import { useRecoilState } from 'recoil';
import { LOAN_SETTINGS } from '../../navigation/routes';

export const LoanList = () => {
  const [value, setValue] = useState(0);
  const [loanFilterState, setloanFilterState] = useRecoilState(loanFilter);
  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setloanFilterState(e.target.value);
  };

  function a11yProps(index: number) {
    console.log('AAlly Props:', index);
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { LOAN_APPLICATION, LOAN_DISBURSED } = FILTER_KEYS;

  console.log('Seriously my page', loanFilterState);

  return (
    <Container>
      <DashboardHeader
        title="BNPL"
        route={`/admin${LOAN_SETTINGS}`}
        buttonPlaceholder="Manage Loan policy"
        Icon={AdminSettingsIcon}
      />
      <TopHeaderCard gap="40px" data={topCardData} />
      <Spacer height="48px" />
      <TableCON>
        <TableHeaderCON>
          {loanFilterState.includes(LOAN_APPLICATION) ? (
            <h3>BNPL Application</h3>
          ) : (
            loanFilterState.includes(LOAN_DISBURSED) && <h3>Loans Disbursed</h3>
          )}

          <SelectField handleChange={handleFilterChange} options={filter} value={loanFilterState} />
        </TableHeaderCON>

        {loanFilterState.includes(LOAN_APPLICATION) ? (
          <Container>
            <CustomAuthTabs value={value} onChange={handleTabChange}>
              <CustomAuthTab label="Request" {...a11yProps(0)} />
              <CustomAuthTab label="Approved" {...a11yProps(1)} />
            </CustomAuthTabs>
            <TabPanel index={0} value={value}>
              <CustomTable columns={loanRequestColumn} data={[]} />
            </TabPanel>
            <TabPanel index={1} value={value}>
              <CustomTable columns={approvedLoanColumn} data={[]} />
            </TabPanel>
          </Container>
        ) : (
          <Container>
            <CustomTable columns={disbursedColumn} data={loans} />
          </Container>
        )}
      </TableCON>
    </Container>
  );
};
