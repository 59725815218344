import styled from '@emotion/styled';
import { breakpoints } from '../../theme/theme';

export const Top = styled.div`
  padding: 2rem;
`;

export const RouteContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  background-color: white;
  overflow-y: scroll;
`;

export const PagelayoutCon = styled.div`
  margin: 0px;
  background: #f9f9f9;
  padding: 0px;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  overflow: hidden;
  max-height: 100vh;
  display: flex;
`;

export const Wrapper = styled.div`
  background: #f9f9f9;
  width: 1216px;
  margin-left: 32px;

  overflow-x: hidden;
  overflow-x: hidden;
  flex: 1;

  @media (max-width: ${breakpoints.md}px) {
    margin-left: 0px;

  }
`;
