import { useState } from 'react';
import { COMMONTYPES } from '../../../TYPES/event.types';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { changePassword } from '../../../network/services/profile';
import { validators } from '../../../utils/validator';
import { Button, InputField, Spacer } from '../../atoms';
import { TogglePassword } from '../../../pages/Auth/styles';
import { HidePassword, ShowPassword } from '../../../assets/Icons';
import { enqueueSnackbar } from 'notistack';

const INITIALSTATE = {
  oldPassword: '',
  password: '',
  confirmPassword: ''
};

export const ChangePassword = () => {
  const [formData, setFormData] = useState(INITIALSTATE);
  const [formError, setFormError] = useState(INITIALSTATE);

  const { oldPassword, password, confirmPassword } = formData;

  const handleChange = (e: COMMONTYPES['inputChange']) => {
    const { name, value } = e.target;
    if (name !== 'oldPassword' && name !== 'confirmPassword') {
      const err = validators[name](value);
      setFormError(oldCred => ({ ...oldCred, [name]: err }));
    }

    setFormData(oldCred => ({ ...oldCred, [name]: value }));
  };

  const [showPassword, setShowPassword] = useState<'text' | 'password'>('password');

  const onTogglePassword = () => {
    if (showPassword === 'text') return setShowPassword('password');
    setShowPassword('text');
  };

  const { mutateAsync, isLoading } = useMutationHook(changePassword, 'CHANGE_PASSWORD');

  const _submit = async (e: COMMONTYPES['buttonClick']) => {
    e.preventDefault();
    try {
      await mutateAsync(formData);
    } catch (error: any) {
      if (error.response.data.message === 'Incorrect old password') {
        enqueueSnackbar('Old password is incorrect', { variant: 'error' });
      }
    }
  };

  const disabled =
    !!formError.password || !!formError.confirmPassword || formData.password !== formData.confirmPassword;

  return (
    <form>
      <InputField
        type={showPassword}
        label="Enter Old Password"
        name="oldPassword"
        value={oldPassword}
        onChange={handleChange}
        placeholder="Old Password"
        // error={formError.oldPassword}
        action={
          <TogglePassword onClick={onTogglePassword}>
            {showPassword !== 'text' ? <ShowPassword /> : <HidePassword />}
          </TogglePassword>
        }
        required
      />
      <Spacer height="32px" />
      <InputField
        type={showPassword}
        label="Enter New Password"
        name="password"
        value={password}
        onChange={handleChange}
        placeholder="New Password"
        error={formError.password}
        action={
          <TogglePassword onClick={onTogglePassword}>
            {showPassword !== 'text' ? <ShowPassword /> : <HidePassword />}
          </TogglePassword>
        }
        required
      />
      <Spacer height="32px" />
      <InputField
        type={showPassword}
        label="Confirm Password"
        name="confirmPassword"
        value={confirmPassword}
        onChange={handleChange}
        placeholder="Confirm Password"
        error={formError.confirmPassword}
        action={
          <TogglePassword onClick={onTogglePassword}>
            {showPassword !== 'text' ? <ShowPassword /> : <HidePassword />}
          </TogglePassword>
        }
        required
      />
      <Spacer height="49px" />
      <Button onClick={_submit} disabled={disabled || isLoading} isLoading={isLoading}>
        Change
      </Button>
    </form>
  );
};
