import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { STORE_KEYS } from '../keys';
const { persistAtom } = recoilPersist();

const credentials: string = '';

export const userAtom = atom({
  key: STORE_KEYS.USER_ATOM,
  default: credentials,
  effects_UNSTABLE: [persistAtom]
});

const defaultToken = {
  access_token: sessionStorage.getItem('access_token') || '',
  refresh_token: sessionStorage.getItem('refresh_token') || ''
};

export const userTokenAtom = atom<typeof defaultToken>({
  key: STORE_KEYS.USER_TOKEN_ATOM,
  default: defaultToken
});

export const userIsLoggedIn = atom<boolean>({
  key: STORE_KEYS.IS_LOGGED_IN,
  default: true
});



export const userRoleAtom = atom<string>({
  key: STORE_KEYS.USER_ROLE,
  default: "",
  effects_UNSTABLE: [persistAtom]
});

export const userFilter = atom<string>({
  key: STORE_KEYS.USER_FILTER,
  default: 'Shoppers'
});


export const sellerFilter = atom<string>({
  key: STORE_KEYS.SELLER_USERS_FILTER,
  default: 'Customer Supports'
});