import { DashboardHeader, TopHeaderCard } from '../../components/molecules';
import { orderColumns } from './data';
import { OrderCON, OrderTableCON } from './style';
import { CustomTable, Spacer } from '../../components/atoms';
import { BrokenGraph, BrokenGclose, AdminUsersIcon, BoxAdd } from '../../assets/Icons';
import { useQuery } from 'react-query';
import { getSellerOrders, getSellerOrdersAnalytics } from '../../network/services/orders';
import { useState } from 'react';

export const OrderList = () => {
  // const { data, isFetchedAfterMount } = useQuery(['admin_orders'], getOrders, { staleTime: Infinity });
  const [page, setPage] = useState(1);
  const pageSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE ? parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE) : 10;
  const { data: ordersData, isFetchedAfterMount } = useQuery(
    ['seller_orders', { page: page - 1, size: pageSize }],
    getSellerOrders,
    // { staleTime: Infinity }
  );

  const sellerOrdersData = ordersData?.data.filter((order: any) => Object.keys(order).length !== 0) || [];

  const orders = sellerOrdersData || [];

  const { data: analyticsData } = useQuery(['seller_orders_analytics'], getSellerOrdersAnalytics, {
    staleTime: Infinity,
    retry: false
  });
  const analytics = analyticsData?.data || {};

  // console.log(canceledOrders);

  const topCardData = [
    {
      title: 'All Orders',
      total: analytics?.all,
      Icon: BrokenGraph,
      iconBg: '#F5FBEF'
      // percentage: '+35.5%'
    },
    {
      title: 'New Orders',
      total: analytics?.newOrder,
      Icon: BoxAdd,
      iconBg: '#FEF9EC'
      // percentage: '+35.5%'
    },

    {
      title: 'Completed Orders',
      total: analytics?.completed,
      Icon: AdminUsersIcon,
      // percentage: '-3.93%',
      iconBg: '#EFF1FB'
    },

    {
      title: 'Pending Orders',
      total: analytics?.pending,
      // percentage: '+25.3%',
      Icon: BrokenGclose,
      iconBg: '#FFF0F3'
    },

    {
      title: 'Canceled Orders',
      total: analytics?.cancelled,
      // percentage: '-26.3%',
      Icon: BrokenGclose,
      iconBg: '#FFF0F3'
    }
  ];

  return (
    <OrderCON>
      <DashboardHeader title="Orders" />
      <TopHeaderCard data={topCardData.slice(0, 5)} />
      <Spacer height="48px" />

      <OrderTableCON>
        <CustomTable
          isLoading={isFetchedAfterMount}
          columns={orderColumns}
          getRowId={(row) => row.productId}
          data={orders}
          totalItems={ordersData?.totalElements ?? 0}
          totalPages={ordersData?.totalPage ?? 0}
          currentPage={page}
          setCurrentPage={setPage}
        />
      </OrderTableCON>
    </OrderCON>
  );
};
