import { styled } from '@mui/material';
import { COLORS } from '../../../theme/color';
import { breakpoints } from '../../../theme/theme';

export const AdsCon = styled('div')`
  background-color: ${COLORS.MainBlue};
  overflow: hidden;
  width: 100%;
  max-height: 5.5rem;
  height: 15vw;
  display: flex;
  justify-content: center;
  position: relative;

  img {
    width: 100%;
    object-fit: contain;
  }

  .hide-con {
    height: 100%;
    position: absolute;
    top: 0;
    right: 20px;
    display: flex;
    align-items: center;
  }
  .hide {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: ${breakpoints.md}px) {
    max-height: 5rem;
    .hide-con {
      transform: scale(0.8);
      right: 3vw;
    }
  }
  @media (max-width: ${breakpoints.sm}px) {
    max-height: 4.5rem;

  }
`;
