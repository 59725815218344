import { styled } from '@mui/material';

export const UserDetailsCon = styled('div')`
  margin-top: 52px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .label {
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
    .value {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: #a18d8e;
    }

    button {
      border-radius: 12px;
      background: #eff1fb;
      width: 102px;
      height: 31px;
      padding: 10px;
      color: #2f4dc4;
      font-size: 13px;
    }
  }
  .action-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
  }
`;


export const ImageCon = styled('div')`
 width: 100%;
 padding: 30px 0px;

 img{
  width: 100%;
  object-fit: cover;
 }
 
`;

export const DeclineBtnExtraStyles = {
  borderRadius: '4px',
  background: '#D90429'
};

export const ApproveBtnExtraStyles = {
  borderRadius: '4px',
};

