import { WalletTransactionHistory } from '../../../TYPES/api.body';
import { Message } from './Message';

type MessageListProp = {
  data: WalletTransactionHistory[];
};

export const MessageList = ({ data }: MessageListProp) => {
  return (
    <div className="list-con">
      {/* {data.sub && <h3>{data.sub}</h3>} */}
      {data.map((mes, index) => (
        <Message data={mes} key={index} />
      ))}
    </div>
  );
};
