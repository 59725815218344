import React from 'react';
import { WalletCardCon } from './styles';

type WalletCardProp = {
  label?: string;
  amount?: string;
  showBgImage?: boolean;
  Icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
};

export const WalletCard = ({ label, amount = '', Icon, showBgImage=true, onClick }: WalletCardProp) => {
  return (
    <WalletCardCon type={amount} showBgImage={showBgImage} onClick={onClick}>
      {amount  ? (
        <div className="card-header">
          <span>{label}</span> {Icon && <Icon />}
        </div>
      ) : (
        <>
          {Icon && <Icon />} <span>{label}</span>
        </>
      )}
      {amount && <div className="amount">{amount}</div>}
    </WalletCardCon>
  );
};
