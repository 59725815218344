/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { changePassword } from '../../../network/services/profile';
import { settingsCurrentRouteAtom } from '../../../store/atoms/settingsAtom';
import { validators } from '../../../utils/validator';
import { ChangePassword } from '../../molecules';
import { Button } from '../Button/Button';
import { Divider } from '../Divider/Divider';
import { InputField } from '../InputField/InputField';
import { FormCON, SettingsInfoCON, InputCON, InputExtraStyles, SubmitButtonCON } from './style';

const INITIAL_STATE = {
  password: '',
  confirmPassword: ''
};

export const SettingsChangePassword = () => {
  const [subCurrentRoute, setSubCurrentRoute] = useRecoilState(settingsCurrentRouteAtom);

  const [formData, setFormData] = useState(INITIAL_STATE);
  const [formError, setFormError] = useState(INITIAL_STATE);

  const onUpdateFormData = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    const error = validators[name](value);
    setFormData(formData => ({ ...formData, [name]: value }));
    setFormError({
      ...formError,
      [name]: error
    });
  };

  const [showPassword, setShowPassword] = useState<'text' | 'password'>('password');

  const onTogglePassword = () => {
    if (showPassword === 'text') return setShowPassword('password');
    setShowPassword('text');
  };

  const { mutateAsync, isLoading } = useMutationHook(changePassword, 'LOGIN');

  const _login = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
  };

  const disabled = false;

  return (
    <SettingsInfoCON>
      <ChangePassword />
    </SettingsInfoCON>
  );
};
