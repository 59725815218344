import { CustomBreadcrumb } from '../../../components/atoms/Breadcrumb';
import { Button, Divider, InputField, SelectField, Spacer } from '../../../components/atoms';
import { useState } from 'react';

import { LoanSettingsCON, FormCON, InputCon, extraStylesInput, LoanHeaderCON } from './style';
import { loanApproveOptions } from '../data';

export const LoanSettings = () => {
  const [loanAproval, setLoanAproval] = useState('Admin');

  return (
    <LoanSettingsCON>
      <LoanHeaderCON>
        <div>
          <CustomBreadcrumb firstLabel="Loans" secondLabel="Loan Settings" />
        </div>
        <Button>Save Changes</Button>
      </LoanHeaderCON>

      <FormCON>
        <h3>BNPL Loan Settings</h3>
        <Divider />

        <Spacer height="26px" />

        <InputCon>
          <span>Loan approval</span>
          <SelectField
            value={loanAproval}
            options={loanApproveOptions}
            handleChange={(e: any) => setLoanAproval(e.target.value)}
          />
        </InputCon>

        <InputCon>
          <span>Interest Per Month</span>
          <InputField type="text" name="name" placeholder="30%" extraStyles={extraStylesInput} required={true} />
        </InputCon>

        <InputCon>
          <span>Upfront payment percentage</span>
          <InputField type="text" name="name" placeholder="30%" extraStyles={extraStylesInput} required={true} />
        </InputCon>

        <InputCon>
          <span>State</span>
          <InputField type="text" name="name" placeholder="30%" extraStyles={extraStylesInput} required={true} />
        </InputCon>

        <Spacer height="4rem" />
      </FormCON>
    </LoanSettingsCON>
  );
};
