import { useState } from 'react';
import { COMMONTYPES } from '../../../TYPES/event.types';
import { CashIcon, ConversionIcon, PlusIcon, ShareIcon, WalletSmallIcon } from '../../../assets/Icons';
import { EmptyCon, Spacer } from '../../../components/atoms';
import { ConvertPointModal, MessageList } from '../../../components/molecules';
import {
  SellerWalletAction,
  SellerWalletActionIcon,
  SellerWalletActions,
  SellerWalletCon,
  SellerWalletHeader,
  SellerWalletSubheader,
} from './styles';
import { walletData } from './demoData';
import { CircularProgress, Divider } from '@mui/material';
import { useQuery } from 'react-query';
// import { useQueries, useQuery } from 'react-query';
import { getSellerWalletBalance, getSellerWalletHistories } from '../../../network/services/wallet';
import SellerWalletStats from './Stats';
// import FundWalletModal from '../../../components/molecules/Wallet/FundWalletModal';
import WithdrawWalletModal from '../../../components/molecules/Wallet/WithdrawWalletModal';
import ConfirmWithdrawModal from '../../../components/molecules/Wallet/ConfirmWithdrawModal';
import { WithdrawWallet } from '../../../TYPES/api.body';

const INITIAL_STATE: WithdrawWallet = {
  amount: 0,
  accountNumber: '',
};

export const SellerWallet = () => {
  const { data: walletHistoriesData, isFetchedAfterMount } = useQuery('wallet_histories', getSellerWalletHistories);
  const walletHistories = walletHistoriesData?.data ?? [];

  const [isPoint, setIsPoint] = useState(false);
  // const [openFundWalletModal, setOpenFundWalletModal] = useState<boolean>(false);
  const [withdrawFormData, setWithdrawFormData] = useState<WithdrawWallet>(INITIAL_STATE);
  const [openWithdrawModal, setOpenWithdrawModal] = useState<boolean>(false);
  const [confirmWithdrawModal, setConfirmWithdrawModal] = useState<boolean>(false);
  const showModal = (e: COMMONTYPES['divClick']) => {
    e.stopPropagation();
    setIsPoint(true);
  };

  // const showFundWalletModal = (e: COMMONTYPES['divClick']) => {
  //   e.stopPropagation();
  //   setOpenFundWalletModal(true);
  // }

  const showWithdrawModal = (e: COMMONTYPES['divClick']) => {
    e.stopPropagation();
    setOpenWithdrawModal(true);
  }

  const showConfirmWithdrawModal = (e: COMMONTYPES['divClick']) => {
    e.stopPropagation();
    setConfirmWithdrawModal(true);
  }
  
  const handleInitiateWithdrawSuccess = () => {
    setOpenWithdrawModal(false);
    setConfirmWithdrawModal(true);
  }



  return (
    <SellerWalletCon>
      <SellerWalletHeader>
        <h3> Wallet</h3>
        <SellerWalletActions>
          {/* <SellerWalletAction>
            <p>Fund Wallet</p>
            <SellerWalletActionIcon onClick={showFundWalletModal}>
              <PlusIcon />
            </SellerWalletActionIcon>
          </SellerWalletAction> */}

          <SellerWalletAction>
            <p>Withdraw</p>
            <SellerWalletActionIcon onClick={showWithdrawModal}>
              <ShareIcon />
            </SellerWalletActionIcon>
          </SellerWalletAction>

          <SellerWalletAction>
            <p>Convert Points</p>
            <SellerWalletActionIcon onClick={showModal}>
              <ConversionIcon />
            </SellerWalletActionIcon>
          </SellerWalletAction>

        </SellerWalletActions>
      </SellerWalletHeader>
      <Spacer height="24px" />
      <Divider />
      <Spacer height="24px" />
      <SellerWalletStats />
      <Spacer height="24px" />
      <SellerWalletSubheader>Wallet History</SellerWalletSubheader>
      {!isFetchedAfterMount && (
        <EmptyCon>
          <CircularProgress size={30} />
        </EmptyCon>
      )}
      {walletHistories.length ? (
        <MessageList data={walletHistories} />
      ) : (
        isFetchedAfterMount && <EmptyCon>No History</EmptyCon>
      )}
      {isPoint && <ConvertPointModal setIsPoint={setIsPoint} />}
      {/* {openFundWalletModal && <FundWalletModal setOpenFundWalletModal={setOpenFundWalletModal} />} */}
      {openWithdrawModal && <WithdrawWalletModal withdrawFormData={withdrawFormData} setWithdrawFormData={setWithdrawFormData} setOpenWithdrawModal={setOpenWithdrawModal} handleInitiateWithdrawSuccess={handleInitiateWithdrawSuccess} />}
      {confirmWithdrawModal && <ConfirmWithdrawModal withdrawFormData={withdrawFormData} setConfirmWithdrawModal={setConfirmWithdrawModal} />}
    </SellerWalletCon>
  );
};
