import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Editor } from '@ckeditor/ckeditor5-core';
import { LegacyRef } from 'react';
type TextEditorProps = {
  onChange: (value: string) => void;
  value?: string;
  inputRef?: LegacyRef<CKEditor<Editor>>;
};
export const TextEditor = ({ onChange, value, inputRef }: TextEditorProps) => {
  return (
    <div>
      <CKEditor
        editor={ClassicEditor as any}
        ref={inputRef}
        data={value}
        onReady={editor => {
          // console.log('Editor is ready to use!', editor);
        }}
        onChange={(event, editor) => {
          onChange(editor.data.get());
        }}
        onBlur={(event, editor) => {
          // console.log('Blur.', editor);
        }}
        onFocus={(event, editor) => {
          // console.log('Focus.', editor);
        }}
      />
    </div>
  );
};
