import { ChangeEvent } from 'react';
import { DashboardHeader, TopHeaderCard } from '../../../components/molecules';
import { BrokenAdd, BrokenUserIcon, BrokenUserTickIcon, UserMinusIcon } from '../../../assets/Icons';
import { verifiedShoppers, accountColumn, filter, subAdminColumns } from './data';
import { useState } from 'react';
import { TableCON, TableHeaderCON } from './style';
import { SELLER_FILTER_KEYS } from '../../../store/keys';
import { Container, CustomTable, SelectField, Spacer } from '../../../components/atoms';
import { sellerFilter } from '../../../store/atoms/userAtom';
import { useRecoilState } from 'recoil';
import { useMediaQuery, Theme } from '@mui/material';
import { useQuery } from 'react-query';
import { getSellerUsers } from '../../../network/services/users';
import { AddUser } from './AddUser';

export const SellerUserList = () => {
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [userFilterState, setuserFilterState] = useRecoilState(sellerFilter);
  const [isAddUser, setAddUser] = useState<boolean>(false);

  const { data: customerSupportsData, isFetchedAfterMount: customersIsFetchedAfterMount } = useQuery(
    ['seller_users', { type: 'customer-supports' }],
    getSellerUsers
  );
  const { data: subAdminsData, isFetchedAfterMount: subAdminsIsFetchedAfterMount } = useQuery(
    ['seller_users', { type: 'admins' }],
    getSellerUsers
  );
  const { data: accountantsData, isFetchedAfterMount: accountantsIsFetchedAfterMount } = useQuery(
    ['seller_users', { type: 'accountants' }],
    getSellerUsers
  );

  const customerSupports = customerSupportsData?.data || [];
  const subAdmins = subAdminsData?.data || [];
  const accountants = accountantsData?.data || [];

  let accountantsCount = 0;
  let customersSupportsCount = 0;
  let subAdminsCount = 0;

  if (Array.isArray(customerSupports) && customersIsFetchedAfterMount) {
    customersSupportsCount = customerSupports.length;
  }

  if (Array.isArray(subAdmins) && subAdminsIsFetchedAfterMount) {
    subAdminsCount = subAdmins.length;
  }

  if (Array.isArray(accountants) && accountantsIsFetchedAfterMount) {
    accountantsCount = accountants.length;
  }

  const topCardData = [
    {
      title: 'Customer Supports',
      total: customersSupportsCount,
      Icon: BrokenUserIcon,
      iconBg: '#F5FBEF'
    },
    {
      title: 'Sub Admins',
      total: subAdminsCount,
      Icon: BrokenUserTickIcon,
      iconBg: '#EFF1FB'
    },

    {
      title: 'Accountants',
      total: accountantsCount,
      Icon: UserMinusIcon,
      iconBg: '#EFF1FB'
    }
  ];

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setuserFilterState(e.target.value);
  };

  // function a11yProps(index: number) {
  //   return {
  //     id: `simple-tab-${index}`,
  //     'aria-controls': `simple-tabpanel-${index}`
  //   };
  // }

  // const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };

  const onAddUserModalClose = () => {
    setAddUser(false);
  };
  const onAddUserModalOpen = () => {
    setAddUser(true);
  };

  const { CUSTOMER_SUPPORTS, ACCOUNTANTS, SUBADMIN } = SELLER_FILTER_KEYS;

  return (
    <Container>
      <DashboardHeader title="Users" buttonPlaceholder="Add User" onClick={onAddUserModalOpen} Icon={BrokenAdd} />
      {/* {userFilterState.includes(CUSTOMER_SUPPORTS) ? (
      ) : (
        <DashboardHeader title="Users" />
      )} */}
      {!md && (
        <div>
          <TopHeaderCard gap="50px" data={topCardData} />
        </div>
      )}

      {md && <TopHeaderCard data={topCardData} />}
      <Spacer height="48px" />
      <TableCON>
        <TableHeaderCON>
          {userFilterState.includes(CUSTOMER_SUPPORTS) ? (
            <h3>Customer Supports</h3>
          ) : userFilterState.includes(ACCOUNTANTS) ? (
            <h3>Accountants</h3>
          ) : (
            userFilterState.includes(SUBADMIN) && <h3>Sub Admins</h3>
          )}

          <SelectField handleChange={handleFilterChange} options={filter} value={userFilterState} />
        </TableHeaderCON>
        {userFilterState.includes(CUSTOMER_SUPPORTS) ? (
          <Container>
              <CustomTable isLoading={customersIsFetchedAfterMount} columns={verifiedShoppers} data={customerSupports} />
          </Container>
        ) : userFilterState.includes(ACCOUNTANTS) ? (
          <CustomTable isLoading={accountantsIsFetchedAfterMount} columns={accountColumn} data={accountants} />
        ) : (
          <Container>
            <CustomTable columns={subAdminColumns} data={subAdmins} />
          </Container>
        )}
      </TableCON>

      {isAddUser && <AddUser onModalClose={onAddUserModalClose} />}
    </Container>
  );
};
