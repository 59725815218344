import styled from '@emotion/styled';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { COLORS } from '../theme/color';
import { Popover } from '@mui/material';

export const ActionCON = styled('div')`
  cursor: pointer;
`;

export const DeclineLoanCON = styled('div')`
  h3 {
    font-family: Outfit;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }
  .button-container{
    margin-top: 30px;
  }
`;

export const InputCON = styled('div')`
  margin-top: 52px;
  span {
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
`;

export const extraSubmitBtnStyles = {
  borderRadius: '4px',
  fontSize: '14px',
  fontWeight: '400'
};

export const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
  margin-top: 16px;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #E1DBDB;

  &:hover {
  }

  &:focus {
    border-color: ${COLORS.MainBlue};
   
  }
`
);


export const OrderDetailsContent = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .label {
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  .value {
    color: #a18d8e;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`;


export const OrderDetailsCON = styled('div')`
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 26px;
`;


export const StatusCON = styled('div')<{ status?: string }>`
  background: ${({ status }) =>
    status?.toLocaleLowerCase().includes('out for delivery')
      ? '#EFF1FB'
      : status?.toLocaleLowerCase().includes('pending')
      ? '#FFF6C7'
      : '#EEFBF4'};
  color: ${({ status }) =>
    status?.toLocaleLowerCase().includes('out for delivery')
      ? COLORS.MainBlue
      : status?.toLocaleLowerCase().includes('pending')
      ? '#F79009'
      : '#27AE60'};
  display: flex;
  padding: 4px 16px;
  font-weight: 500;
  align-items: center;
  border-radius: 8px;
  gap: 8px;

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    text-transform: capitalize;
    line-height: 24px;
  }
`;


export const CustomPopoverCON = styled(Popover)`
  .popover-content {
    padding: 30px 0;
    width: 160px;
    background-color: white;
    padding: 10px 0px;
    /* box-shadow: none; */
  }
  div {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.06);
  }
`;

export const PopoverContent = styled('div')<{ color?: string }>`
  cursor: pointer;
  display: flex;
  gap: 10px;
  padding: 10px 16px;

  &:hover {
    background: ${COLORS.MildGray};
  }
 
  p {
    font-size: 14px;
    font-weight: 400;
    color: ${({ color }) => color};
  }
`;


export const extraButtonStyles = {
  color: `${COLORS.MainBlue}`,
  backgroundColor: '#EFF1FB',
  borderRadius: '12px',
  height: '31px',
  width: '102px',
  fontSize: '14px',
  fontWeight: '400',
};