import React from 'react';
import dayjs from 'dayjs';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { ChartCON } from './style';
// import ChartLegendItem from '../../atoms/ChartLegendItem';
import { useQuery } from 'react-query';
import { sellerTotalProfitsStatistics } from '../../../network/services/orders';
// import { Button } from '../../atoms';
// import { ExportIcon } from '../../../assets/Icons';

type SalesReportChartProps = {
  width?: string;
};

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend, Filler);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'top' as const
    },
    title: {
      display: false,
      text: 'Sales Report'
    }
  }
  // maintainAspectRatio: false,
};

const labels = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'Novemeber',
  'December'
];

const TotalProfitsChart = ({ width }: SalesReportChartProps) => {
  const [exportDate, setExportDate] = React.useState<string>(dayjs().format('YYYY-MM-DD'));
  const { data: totalProfitsData } = useQuery(
    ['admin_total_profits_statistics', { exportDate }],
    sellerTotalProfitsStatistics
  );

  const totalProfitChartData = totalProfitsData?.data?.map((item: { totalProfit: any }) => item.totalProfit) || [];

  // const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setExportDate(e.target.value);
  // };

  const barChartData = {
    labels,
    datasets: [
      {
        label: 'Total Profits',
        data: totalProfitChartData,
        backgroundColor: '#d9d9d9',
        borderRadius: 20,
        hoverBackgroundColor: '#1AB66E',
        barThickness: 20
      }
    ]
  };

  return (
    <ChartCON width={width}>
      <div className="header">
        <div className="metadata">
          <h3>Total Profit</h3>
        </div>
        {/* <div className="export">
            <h4>Export</h4>
            <InputField type="date" name="export" value={exportDate} onChange={onInputChange} />
            <Button onClick={onClick} extraStyles={buttonExtraStyles}>
              <ExportIcon />
            </Button>
          </div> */}
      </div>
      <Bar options={options} data={barChartData} height="300" />
    </ChartCON>
  );
};

export default TotalProfitsChart;
