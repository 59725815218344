import { styled } from '@mui/material';
import { COLORS } from '../../theme/color';

export const ProductCON = styled('div')``;

export const ProductTableCON = styled('div')`
  background: ${COLORS.White};
`;

export const TableHeaderCON = styled('div')`
  padding: 32px 32px;
`;

export const ProductStatusCON = styled('div')<{ status?: string }>`
  background: ${({ status }) => (status === 'available' ? '#F0F5EA' : '#FEECEB')};
  color: ${({ status }) => (status === 'available' ? '#669F2A' : '#F04438')};
  display: flex;
  padding: 4px 16px;
  align-items: center;
  border-radius: 8px;
  gap: 8px;

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    text-transform: capitalize;
    line-height: 24px;
  }
`;

export const ActionCON = styled('div')`
  cursor: pointer;
`;

export const ProductImgCon = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50px;
  }
`;
