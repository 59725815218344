export const STORE_KEYS = {
  USER_TOKEN_ATOM: '@userTokenAtom',
  USER_ATOM: '@userAtom',
  IS_LOGGED_IN: '@IsLoggedIn',
  ORDER_FILTER: '@orderFilter',
  USER_FILTER: '@userFilter',
  GOOD_DETAIL: '@goodsDetails',
  IS_GOOD_OPEN_DETAIL: '@IsgoodsDetailsOpen',
  IS_CART_OPEN: '@IsCartOpen',
  CART_LIST: '@cartList',
  CART_LENGTH: '@cartLength',
  CART_AMOUNT_TOTAL: '@cartAmountTotal',
  WISH_LIST: '@wishList',
  USER_ROLE: '@userRole',
  BUSINESS_KYB: '@businessKYB',
  FILFULMENT_FILTER: '@fulfulmentFilter',
  LOAN_FILTER: '@loanFilter',
  ORDER_ACTION_POPOVER: '@orderActionPopover',
  UPDATE_BUSINESS_AUTH_STEP: '@businessAuthStep',
  CONTACT_INFO: '@contactInfo',
  SELLER_USERS_FILTER: '@sellerUsersFilter',
  DELIVERY_STATUS_FILTER: '@deliveryStatusFilter',
  DELIVERY_ACTION_POPOVER: '@deliveryActionPopover'
} as const;

export const FILTER_KEYS = {
  SELLERS: 'Sellers',
  BUSINESSES: 'Businesses',
  SHOPPERS: 'Shoppers',
  PENDING_ORDER: 'Pending',
  COMPLETED_ORDER: 'Completed',
  CANCELED_ORDERS: 'Canceled',
  NEW_ORDER: 'New',
  CONFIRMED_FULFILMENT: 'Confirmed',
  AWAITING_FULFILMENT: 'Awaiting',
  LOAN_APPLICATION: 'Application',
  LOAN_DISBURSED: 'Disbursed',
  SELLER_ROLE: 'seller',
  ADMIN_ROLE: 'admin'
} as const;

export const SELLER_FILTER_KEYS = {
  CUSTOMER_SUPPORTS: 'Customer Supports',
  ACCOUNTANTS: 'Accountants',
  SUBADMIN: 'Sub Admins'
} as const;

export const DELIVERY_STATUS_FILTER_KEYS = {
  DELIVERED: 'Delivered',
  DELIVERY_REQUEST: 'Delivery Request'
} as const;

export const QUERY_KEYS = {
  SELLER_BANK_DETAILS: 'sellerBankDetails'
} as const;
