import { CustomTab, CustomTabs, DashboardHeader, TabPanel, a11yProps } from '../../../components/molecules';
import { SettingCON, SettingsRight, SettingsTab, SettingsTabs } from './style';
import {
  Divider,
  FAQs,
  Prefrence,
  SettingsChangePassword,
  SettingsInformation,
  Spacer,
  Verify
} from '../../../components/atoms';
import { Grid } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useState } from 'react';
import { settingsCurrentRouteAtom } from '../../../store/atoms/settingsAtom';
import { SettingsBankDetails } from '../../../components/atoms/Settings/bankDetails';

export const Settings = () => {
  const [currentRoute, setCurrentRoute] = useState(0);
  const [subCurrentRoute, setSubCurrentRoute] = useRecoilState(settingsCurrentRouteAtom);
  const handleChange = (event: React.SyntheticEvent<Element, Event>, value: any) => {
    setCurrentRoute(value);
  };

  const handleChangeSubCurrentRoute = (event: React.SyntheticEvent<Element, Event>, value: any) => {
    setSubCurrentRoute(value);
  };
  return (
    <SettingCON>
      <DashboardHeader title="Settings" />

      <Spacer height="2rem" />
      <Divider />

      <Spacer height="2rem" />
      <Grid container gap="40px">
        <Grid xs={12} lg={2}>
          <CustomTabs
            value={currentRoute}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            orientation="vertical">
            <CustomTab label="Account" {...a11yProps(0)} disableRipple iconPosition="start" />
            <CustomTab label="Notification" {...a11yProps(1)} disableRipple iconPosition="start" />
            {/* <CustomTab label="Privacy Policy" {...a11yProps(2)} disableRipple iconPosition="start" />
            <CustomTab label="FAQ" {...a11yProps(3)} disableRipple iconPosition="start" /> */}
          </CustomTabs>
        </Grid>
        <Grid xs={12} lg={8.7} item>
          <SettingsRight>
            <TabPanel value={currentRoute} index={0}>
              {subCurrentRoute !== 3 && (
                <SettingsTabs
                  value={subCurrentRoute}
                  onChange={handleChangeSubCurrentRoute}
                  variant="scrollable"
                  scrollButtons="auto"
                  orientation="horizontal">
                  <SettingsTab label="Information" {...a11yProps(0)} disableRipple iconPosition="start" />
                  <SettingsTab label="Password" {...a11yProps(0)} disableRipple iconPosition="start" />
                  <SettingsTab label="Bank Details" {...a11yProps(0)} disableRipple iconPosition="start" />
                </SettingsTabs>
              )}
              <Spacer height="56px" />
              <TabPanel index={0} value={subCurrentRoute}>
                <SettingsInformation />
              </TabPanel>
              <TabPanel index={1} value={subCurrentRoute}>
                <SettingsChangePassword />
              </TabPanel>
              {/* <TabPanel index={2} value={subCurrentRoute}>
                <Verify />
              </TabPanel> */}
              <TabPanel index={2} value={subCurrentRoute}>
                <SettingsBankDetails />
              </TabPanel>
            </TabPanel>
            {/* <TabPanel value={currentRoute} index={1}>
              <Prefrence />
            </TabPanel>
            <TabPanel value={currentRoute} index={3}>
              <FAQs />
            </TabPanel> */}
          </SettingsRight>
        </Grid>
      </Grid>
    </SettingCON>
  );
};
