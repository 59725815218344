import { styled } from "@mui/material";
import { COLORS } from "../../../theme/color";
import { breakpoints } from "../../../theme/theme";

export const TrackHistoryCon = styled('div')`
  width: 100%;
  max-width: 854px;
  padding: 0 32px;
  background: #ffffff;

  .cancel {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    font-size: 16px;
    font-weight: 500;
    .delivered{
      color: ${COLORS.MainGreen};
    }
    .out-for-delivery{
      color: ${COLORS.MainBlue};
    }
  }
  @media (max-width:${breakpoints.sm}px) {
    padding: 0 5vw;
  }
`;

export const VerticalDivider = styled('div')<{ done: boolean }>`
  width: 0;
  height: 56px;
  outline: ${props => (props.done ? '1.5px solid ' + COLORS.MainBlue : '1.5px dashed #ACA2A2')};
  margin: 2px 0 2px 11.5px;
`;

export const TimeLine = styled('div')<{ done: boolean }>`
  display: flex;
  align-items: center;
  margin: ${props => !props.done && '7px 0'};

  .label {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${props => (props.done ? COLORS.MainBlack : '#ACA2A2')};
    margin-bottom: ${props => props.done && '5px'};
  }

  svg {
    fill: ${props => (props.done ? COLORS.MainBlue : '#ACA2A2')};
  }

  .time {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: #483d3d;
  }
`;