import React, { Dispatch, SetStateAction, useState } from 'react';
import { Modal } from '../Modal/Modal';
import { WalletModalCon, OtpInputCon } from './styles';
import { useQueryClient } from 'react-query';
import { Button, Spacer } from '../../atoms';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { WithdrawWallet } from '../../../TYPES/api.body';
import { confirmWithdrawWallet } from '../../../network/services/wallet';
import OtpInputFields from '../../atoms/OtpInputFields/otpInputFields';
import { enqueueSnackbar } from 'notistack';

type Props = {
  withdrawFormData: WithdrawWallet;
  setConfirmWithdrawModal: Dispatch<SetStateAction<boolean>>;
};

function ConfirmWithdrawModal({ withdrawFormData, setConfirmWithdrawModal }: Props) {
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutationHook(confirmWithdrawWallet, 'CONFIRM_WALLET_WITHDRAWAL', true);

  const _handleConfirmWalletWithdrawal = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    try {
      const payload = { ...withdrawFormData, otp: otp.join('') };
      const data = await mutateAsync(payload);

      // @ts-ignore
      if (data.status.toLowerCase() === 'success') {
        queryClient.invalidateQueries('wallet_balance');
        onModalClose();
      } 
    } catch (error: any) {
      const message = error.response.data.message || "Something went wrong";
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const onModalClose = () => {
    setConfirmWithdrawModal(false);
  };

  const disabled = otp.length < 6;

  return (
    <Modal title="Confirmation Code" maxWidth="500px" onModalClose={onModalClose}>
      <WalletModalCon>
        <OtpInputCon>
          <OtpInputFields otp={otp} setOtp={setOtp} />
        </OtpInputCon>
        <Spacer height="32px" />

        <Button onClick={_handleConfirmWalletWithdrawal} isLoading={isLoading} disabled={disabled}>
          Confirm{' '}
        </Button>
      </WalletModalCon>
    </Modal>
  );
}

export default ConfirmWithdrawModal;
