import styled from '@emotion/styled';

interface LegendIndicatorProps {
  gap?: string;
  colorHex: string;
}

export const ChartLegendItemCON = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  > *:not(:last-child) {
    margin-right: 0.5rem;
  }
  .label {
    font-size: 1.2rem;
    line-height: 2.4rem;
    font-weight: 500;
    color: #000000;
  }
`;

export const LegendIndicator = styled('div')<LegendIndicatorProps>`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 100%;
  background-color: ${props => props.colorHex};
`;
