import { Theme, useMediaQuery } from '@mui/material';
import { Button, ButtonSelected, List, Stylednav } from './style';
import { typeUsepagination, usePagination } from './usePagination';

interface paginateNavigationCom extends typeUsepagination {
  setCurrentPage: (page: number) => void;
  isDisabled?: boolean;
}

const arrowStyle: Record<'left' | 'right', {}> = {
  left: {
    borderTopLeftRadius: '6px',
    borderLeft: '1px solid #EEEEEE',
    borderBottomLeftRadius: '6px',
    background: 'rgba(47, 77, 196, 0.3)'
  },
  right: {
    borderTopRightRadius: '6px',
    borderBottomRightRadius: '6px',
    background: 'rgba(47, 77, 196, 0.3)'
  }
};

export const PaginationCustom = ({ currentPage, pages, setCurrentPage, isDisabled }: paginateNavigationCom) => {
  const matchesSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const { paginationItems } = usePagination({
    pages,
    currentPage
  });

  const onArrowClick = (type?: 'left' | 'right') => {
    if (!type) {
      return;
    }

    if (type === 'left' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    if (type === 'right' && currentPage < pages) {
      setCurrentPage(currentPage + 1);
    }

    return;
  };

  if (paginationItems.length < 2) {
    return null;
  }

  return (
    <Stylednav>
      {!matchesSm && (
        <p>
          Showing page {currentPage} of {pages}
        </p>
      )}
      <List>
        {paginationItems.map((item, i) => {
          if (item.label && item.label === currentPage) {
            return (
              <ButtonSelected disabled={isDisabled} key={i}>
                {item.label}
              </ButtonSelected>
            );
          }
          if (item.label) {
            return (
              <Button key={i} disabled={isDisabled} onClick={() => setCurrentPage(item.label as number)}>
                {item.label}
              </Button>
            );
          }

          if (item.icon && item.type) {
            return (
              <Button
                key={i}
                style={arrowStyle[item.type]}
                disabled={isDisabled}
                onClick={() => onArrowClick(item.type)}>
                <item.icon color='#fff' />
              </Button>
            );
          }
          return null;
        })}
      </List>
    </Stylednav>
  );
};
