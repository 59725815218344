import React, { HTMLInputTypeAttribute } from 'react';
import { ActionContainer, ErrorType, IconCON, Label, SecLabel, StyledInput } from './style';
import { CalendarIcon } from '../../../assets/Icons';

type BaseInputProps = {
  type?: HTMLInputTypeAttribute;
  name: string;
  value?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  autoComplete?: string;
  error?: string | undefined | number;
  disabled?: boolean;
  required?: boolean;
  backgroundColor?: string;
  label?: string;
  secLabel?: string;
  extraStyles?: Record<string, string | {}>;
  onKeyUp?: any;
  tabIndex?: number;
  min?: number;
  maxLength?: number;
  icon?: any;
  pattern?: RegExp;
  labelStyle?: Record<string, string>;
  defaultValue?: string;
};

export const CustomDateInputField: React.FC<BaseInputProps> = ({
  type = 'text',
  name,
  placeholder,
  value,
  onChange,
  autoComplete,
  error,
  icon,
  disabled,
  min = 0,
  label,
  extraStyles,
  labelStyle,
  secLabel,
  onKeyUp,
  tabIndex,
  maxLength,
  required,
  defaultValue
}) => {
  const ref = React.useRef<HTMLInputElement>(null);

  return (
    <div style={{ width: '100%' }}>
      {label && <Label style={labelStyle}>{label}</Label>}
      {secLabel && <SecLabel>{secLabel}</SecLabel>}
      <ActionContainer>
        <StyledInput
          ref={ref}
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          min={min}
          maxLength={maxLength}
          icon={icon}
          onChange={onChange}
          autoComplete={autoComplete}
          disabled={disabled}
          style={extraStyles}
          defaultValue={defaultValue}
          tabIndex={tabIndex}
          onKeyUp={onKeyUp}
          onWheel={(e: any) => e.target.blur()}
          required={required}
        />
        <IconCON>
          <CalendarIcon onClick={() => ref.current?.showPicker()} />
        </IconCON>
      </ActionContainer>
      {error && typeof error === 'string' && <ErrorType>{error}</ErrorType>}
    </div>
  );
};
