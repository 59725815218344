import { ChangeEvent } from 'react';
import { DashboardHeader, TopHeaderCard } from '../../components/molecules';
import { topCardData, confirmedColumn, awaitingFulfilment, fulfilment, filter } from './data';
import { OrderCON, OrderTableCON, TableHeaderCON } from './style';
import { CustomTable, SelectField, Spacer } from '../../components/atoms';
import { fulfilmentFilter } from '../../store/atoms/fulfilmentAtom';
import { FILTER_KEYS } from '../../store/keys';
import { useRecoilState } from 'recoil';

export const FulfilmentList = () => {
  const [fulfilmentFilterState, setfulfilmentFilterState] = useRecoilState(fulfilmentFilter);

  const { CONFIRMED_FULFILMENT, AWAITING_FULFILMENT } = FILTER_KEYS;

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setfulfilmentFilterState(e.target.value);
  };

  return (
    <OrderCON>
      <DashboardHeader title="Fulfilment" />
      <TopHeaderCard gap='50px' data={topCardData} />
      <Spacer height="48px" />

      <OrderTableCON>
        <TableHeaderCON>
          {fulfilmentFilterState.includes(CONFIRMED_FULFILMENT) ? (
            <h3>Confirmed Fulfilment</h3>
          ) : (
            fulfilmentFilterState.includes(AWAITING_FULFILMENT) && <h3>Awaiting Fulfilment</h3>
          )}

          <SelectField handleChange={handleFilterChange} options={filter} value={fulfilmentFilterState} />
        </TableHeaderCON>
        {fulfilmentFilterState.includes(CONFIRMED_FULFILMENT) ? (
          <CustomTable columns={confirmedColumn} data={fulfilment} />
        ) : (
          fulfilmentFilterState.includes(AWAITING_FULFILMENT) && (
            <CustomTable columns={awaitingFulfilment} data={fulfilment} />
          )
        )}
      </OrderTableCON>
    </OrderCON>
  );
};
