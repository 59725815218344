import { atom } from 'recoil';
import { STORE_KEYS } from '../keys';

export const orderFilter = atom<string>({
  key: STORE_KEYS.ORDER_FILTER,
  default: 'Pending'
});

export const actionPopover = atom<HTMLElement | null>({
  key: STORE_KEYS.ORDER_ACTION_POPOVER,
  default: null
});
