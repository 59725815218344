import { Link } from 'react-router-dom';
import { AnnounceIcon, ConversionIcon, ShipIcon, WalletSmallIcon } from '../../../assets/Icons';
import { WalletTransactionHistory } from '../../../TYPES/api.body';
import { MessageCon } from './styles';

type MessageProp = {
  data: WalletTransactionHistory;
};

export const Message = ({ data }: MessageProp) => {
  // const demoLink = '/account/orders/completed/23467990/track';
  return (
    <MessageCon>
      <div className="icon-con">
        {/* {data.transactionType === 'shipment' && <ShipIcon />} */}
        {/* {data.transactionType === 'announcement' && <AnnounceIcon />} */}
        {data.transactionType === 'conversion' && <ConversionIcon fill="#2F4DC4" />}
        {(data.transactionType === 'transaction' || data.transactionType === 'credit') && <WalletSmallIcon />}
      </div>
      <div className="content">
        <div className="title">
          {data.description}
          <span className="time">{data.transactionDate}</span>
        </div>
        <p>
          {data.description}
          {/* {data.transactionType === '' && <Link to={demoLink}>Track</Link>} */}
        </p>
        <span className="time time-md">{data.transactionDate}</span>
      </div>
    </MessageCon>
  );
};
