import { BrokenBox, BrokenAdd } from '../../assets/Icons';
import { extraButtonStyles } from './style';
import { ActionCON } from '../Product/style';
import { Button } from '../../components/atoms';
import { LoanAction } from '../../actions';
export const topCardData = [
  {
    title: ' Applications',
    total: 0,
    Icon: BrokenAdd,
    iconBg: '#EFF1FB'
  },
  {
    title: ' Disbursed',
    total: 0,
    Icon: BrokenBox,
    iconBg: '#F5FBEF'
  }
];

export const filter = [
  {
    label: 'Loan Application',
    value: 'Application'
  },

  {
    label: 'Loans Disbursed',
    value: 'Disbursed'
  }
];

export const loanApproveOptions = [
  {
    label: 'Admin',
    value: 'Admin'
  },

  {
    label: 'Seller',
    value: 'Seller'
  }
];

export const disbursedColumn = [
  {
    id: 1,
    field: 'name',
    headerName: 'Request by',
    flex: 1,
    minWidth: 150
  },

  {
    id: 2,
    field: 'amount',
    headerName: 'Amount',
    flex: 1,
    minWidth: 200
  },

  {
    id: 3,
    field: 'requestDate',
    headerName: 'Request Date',
    flex: 1,
    minWidth: 100
  },

  {
    id: 4,
    field: 'disbursementDate',
    headerName: 'Disbursement Date',
    flex: 1,
    minWidth: 100
  }
];

export const loanRequestColumn = [
  {
    id: 1,
    field: 'name',
    headerName: 'Request by',
    flex: 2,
    minWidth: 150
  },

  {
    id: 2,
    field: 'amount',
    headerName: 'Amount',
    flex: 1,
    minWidth: 200
  },

  {
    id: 3,
    field: 'requestDate',
    headerName: 'Request Date',
    flex: 2,
    minWidth: 100
  },

  {
    id: 4,
    field: 'disbursementDate',
    headerName: 'Due Date',
    flex: 2,
    minWidth: 100
  },

  {
    id: 8,
    field: 'action',
    headerName: 'Action',
    flex: 0,
    minWidth: 50,
    renderCell: ({ row }: any) => <LoanAction />
  }
];

export const approvedLoanColumn = [
  {
    id: 1,
    field: 'name',
    headerName: 'Request by',
    flex: 1,
    minWidth: 150
  },

  {
    id: 2,
    field: 'amount',
    headerName: 'Amount',
    flex: 1,
    minWidth: 200
  },

  {
    id: 3,
    field: 'requestDate',
    headerName: 'Request Date',
    flex: 1,
    minWidth: 100
  },

  {
    id: 4,
    field: 'disbursementDate',
    headerName: 'Due Date',
    flex: 1,
    minWidth: 100
  },

  {
    id: 8,
    field: 'action',
    headerName: 'Action',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => (
      <ActionCON>
        <Button extraStyles={extraButtonStyles}>Disburse Loan</Button>
      </ActionCON>
    )
  }
];

export const loans = [
  {
    id: 1,
    key: 'name',
    amount: 'N50,000',
    requestDate: '12th January, 2023',
    disbursementDate: '12th January, 2023',
    name: 'Eleanor Pena'
  },
  {
    id: 1,
    key: 'name',
    amount: 'N50,000',
    requestDate: '12th January, 2023',
    disbursementDate: '12th January, 2023',
    name: 'Eleanor Pena'
  },
  {
    id: 1,
    key: 'name',
    amount: 'N50,000',
    requestDate: '12th January, 2023',
    disbursementDate: '12th January, 2023',
    name: 'Eleanor Pena'
  },
  {
    id: 1,
    key: 'name',
    amount: 'N50,000',
    requestDate: '12th January, 2023',
    disbursementDate: '12th January, 2023',
    name: 'Eleanor Pena'
  },
  {
    id: 1,
    key: 'name',
    amount: 'N50,000',
    requestDate: '12th January, 2023',
    disbursementDate: '12th January, 2023',
    name: 'Eleanor Pena'
  }
];
