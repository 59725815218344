import React from 'react';

import { CustomSelect } from './styles';

import { OutlinedInput, MenuItem, Stack, Chip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';

type CustomMultiSelectProps = {
  selectedOptions: string[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
  options: string[];
};

function CustomMultiSelect({ selectedOptions, setSelectedOptions, options }: CustomMultiSelectProps) {
  return (
    <CustomSelect
      multiple
      value={selectedOptions}
      onChange={e => setSelectedOptions(e.target.value as string[])}
      input={<OutlinedInput label="Multiple Select" />}
      renderValue={selected => (
        <Stack gap={1} direction="row" flexWrap="wrap">
          {(selected as any[]).map((value: string) => (
            <Chip
              key={value}
              label={value}
              onDelete={() => setSelectedOptions(selectedOptions.filter(item => item !== value))}
              deleteIcon={<CancelIcon onMouseDown={event => event.stopPropagation()} />}
            />
          ))}
        </Stack>
      )}>
      {options.map((option: any) => (
        <MenuItem key={option.id ?? option} value={option.name ?? option} sx={{ justifyContent: 'space-between' }}>
          {option.name ?? option}
          {selectedOptions.includes(option.name ?? option) ? <CheckIcon color="info" /> : null}
        </MenuItem>
      ))}
    </CustomSelect>
  );
}

export default CustomMultiSelect;
