import { Route, Routes } from 'react-router-dom';
// import Login from '../pages/Auth/Login';
import SellerLogin from '../pages/Auth/Seller/Login';
// import DashboardRoutes from './DashboardRoutes/DashboardRoutes';
import ProtectedRoute from './ProtectedRoutes';
import SellerRoutes from './SellerRoutes/SellerRoutes';
import { OTP, SELLER, SIGN_UP, FORGOTPASSWORD, RESET_NEW_PASSWORD } from './routes';
// import { ADMIN, HOME, OTP, FORGOTPASSWORD, SELLER, SIGN_UP } from './routes';
import ForgotPassword from '../pages/Auth/Seller/ForgotPassword';
import SellerProtectedRoute from './SellerRoutes/SellerProtectedRoutes';
import { Otp, SignUp } from '../pages';
import ResetNewPassword from '../pages/Auth/Seller/ResetNewPassword';

export const AppNavigation = () => {
  return (
    <>
      <Routes>
        <Route element={<ProtectedRoute />}></Route>

        <Route path={`/`} element={<SellerLogin />} />
        {/* <Route path={HOME} element={<Login />} /> */}
        <Route path={FORGOTPASSWORD} element={<ForgotPassword />} />
        <Route path={`${FORGOTPASSWORD}${OTP}`} element={<Otp />} />
        <Route path={`${RESET_NEW_PASSWORD}`} element={<ResetNewPassword />} />
        {/* <Route element={<ProtectedRoute authRequired={true} />}>
          <Route path={`${ADMIN}/*`} element={<DashboardRoutes />} />
        </Route> */}

        <Route element={<SellerProtectedRoute authRequired={true} />}>
          <Route path={`${SELLER}/*`} element={<SellerRoutes />} />
        </Route>

        <Route path={`${SIGN_UP}`} element={<SignUp />} />
        <Route path={OTP} element={<Otp />} />
        <Route path={`${FORGOTPASSWORD}${OTP}`} element={<Otp />} />
        <Route element={<ProtectedRoute authRequired />}></Route>
      </Routes>
    </>
  );
};
