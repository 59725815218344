import React from 'react';
import dayjs from 'dayjs';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { ChartCON } from './style';
import ChartLegendItem from '../../atoms/ChartLegendItem';
import { Button, InputField } from '../../atoms';
import { ExportIcon } from '../../../assets/Icons';
import { useQuery } from 'react-query';
import { CSVLink } from 'react-csv';
import { sellerOrdersStatistics, sellerSalesStatistics } from '../../../network/services/orders';
import { CustomDateInputField } from '../../atoms/CustomDateInputField/CustomDateInputField';

type SalesReportChartProps = {
  width?: string;
  height?: string;
};

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'top' as const
    },
    filler: {
      propagate: true
    },
    title: {
      display: false,
      text: 'Sales Report'
    }
  }
};

const labels = [
  '12AM - 2AM',
  '2AM - 4AM',
  '4AM - 6AM',
  '6AM - 8AM',
  '8AM - 10AM',
  '10AM - 12PM',
  '12PM - 2PM',
  '2PM - 4PM',
  '4PM - 6PM',
  '6PM - 8PM',
  '8PM - 10PM',
  '10PM - 12AM'
];

const SalesReportChart = ({ width, height }: SalesReportChartProps) => {
  const [exportDate, setExportDate] = React.useState<string>(dayjs().format('YYYY-MM-DD'));
  const { data: ordersData } = useQuery(['admin_orders_statistics', { exportDate }], sellerOrdersStatistics);
  const { data: salesData } = useQuery(['admin_sales_statistics', { exportDate }], sellerSalesStatistics);

  // Prepare the data for CSV export
  const csvData =
    // @ts-ignore
    ordersData?.data?.map((item, index) => ({
      hour: labels[index],
      totalOrders: item.totalOrder,
      totalSales: salesData?.data[index]?.totalSales || 0
    })) || [];

  // Define the headers for the CSV file
  const csvHeaders = [
    { label: 'Hour', key: 'hour' },
    { label: 'Total Orders', key: 'totalOrders' },
    { label: 'Total Sales', key: 'totalSales' }
  ];

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExportDate(e.target.value);
  };

  const buttonExtraStyles = {
    borderRadius: '10px'
  };

  // Assuming you want to use 'totalSales' for the data points
  const orderChartData = ordersData?.data?.map((item: { totalOrder: any }) => item.totalOrder) || [];
  const salesChartData = salesData?.data?.map((item: { totalSales: any }) => item.totalSales) || [];

  const lineChartData = {
    labels,
    datasets: [
      {
        label: 'Orders',
        data: orderChartData,
        borderColor: '#F17B0E',
        backgroundColor: '#F6AE591A',
        tension: 0.15,
        fill: true
      },
      {
        label: 'Sales',
        data: salesChartData,
        borderColor: '#0CFF6D',
        backgroundColor: '#0CFF6D1A',
        tension: 0.15,
        fill: true
      }
    ]
  };

  return (
    <ChartCON width={width}>
      <div className="header">
        <div className="metadata">
          <h3>Sales Report</h3>
          <div className="legend">
            <ChartLegendItem colorHex="#F17B0E" labelName="Order" />
            <ChartLegendItem colorHex="#0CFF6D" labelName="Sales" />
          </div>
        </div>
        <div className="export">
          <h4>Export:</h4>
          {/* <InputField type="date" name="export" value={exportDate} onChange={onInputChange} /> */}
          <CustomDateInputField type="date" name="export" value={exportDate} onChange={onInputChange} />
          <CSVLink data={csvData} headers={csvHeaders} filename="sales-report.csv" target="_blank">
            <Button extraStyles={buttonExtraStyles}>
              <ExportIcon />
            </Button>
          </CSVLink>
        </div>
      </div>
      <Line options={options} data={lineChartData} height={height} />
    </ChartCON>
  );
};

export default SalesReportChart;
