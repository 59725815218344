import React from 'react';
import { Outlet } from 'react-router-dom';
import { adminNavs } from '../../navigation/_DashboardRoutes/navs';
import { TopNavBar } from '../molecules';
import { SideNav } from '../molecules';
import { PagelayoutCon, Wrapper } from './styles';
import { userRoleAtom } from '../../store/atoms/userAtom';
import { useMediaQuery, Theme } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { sellerNavs } from '../../navigation/SellerRoutes/navs';
import { FILTER_KEYS } from '../../store/keys';

type PageLayoutProps = {
  children?: React.ReactNode;
  out?: boolean;
};

const PageLayout = ({ children, out }: PageLayoutProps) => {
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const userRole = useRecoilValue(userRoleAtom);
  const { ADMIN_ROLE, SELLER_ROLE } = FILTER_KEYS;

  return (
    <PagelayoutCon>
      {!md && (
        <SideNav navs={userRole.includes(SELLER_ROLE) ? sellerNavs : userRole.includes(ADMIN_ROLE) ? adminNavs : []} />
      )}
      <Wrapper>
        <TopNavBar />

        {out ? <Outlet /> : children}
      </Wrapper>
    </PagelayoutCon>
  );
};

export default PageLayout;
