import { DashboardHeader } from '../../components/molecules';
import {  columns, products } from './data';
import { CustomerSupportCON, CustomerSupportTableCON } from './style';
import { CustomTable, Spacer } from '../../components/atoms';

export const CustomerSupport = () => {
  return (
    <CustomerSupportCON>
      <DashboardHeader
        title="Customer Support"
        
      />

      <Spacer height="2rem" />
      <CustomerSupportTableCON>
        <CustomTable columns={columns} data={products} />
      </CustomerSupportTableCON>
    </CustomerSupportCON>
  );
};
