import { atom } from 'recoil';

export const declineLoanAction = atom<boolean>({
  key: 'declineLoanAction',
  default: false
});


export const viewOrderModal = atom<boolean>({
  key: 'viewOrderModal',
  default: false
});
