import { ChangeEvent, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import { HidePassword, ShowPassword } from '../../../assets/Icons';
import { Button, Container, Spacer } from '../../../components/atoms';
import { InputField } from '../../../components/atoms/InputField/InputField';
import { useNavigate } from 'react-router-dom';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { login, requestOtp } from '../../../network/services/auth';
import { userAtom, userIsLoggedIn, userRoleAtom } from '../../../store/atoms/userAtom';
import { validators } from '../../../utils/validator';
import { AuthContainer, extraStylesInput, InputCon, TogglePassword } from '.././styles';
import { useSetRecoilState } from 'recoil';
import { SIGN_UP } from '../../../navigation/routes';

// const INITIALSTATE = {
//   email: '',
//   password: ''
// };

const SellerLogin = () => {
  const setUserStatus = useSetRecoilState(userIsLoggedIn);
  const setUserRole = useSetRecoilState(userRoleAtom);
  const setUser = useSetRecoilState(userAtom);
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [formError, setFormError] = useState({
    email: '',
    password: ''
  });

  const onUpdateFormData = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    const error = validators[name](value);
    setFormData(formData => ({ ...formData, [name]: value }));
    setFormError({
      ...formError,
      [name]: error
    });
  };

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState<'text' | 'password'>('password');

  const onTogglePassword = () => {
    if (showPassword === 'text') return setShowPassword('password');
    setShowPassword('text');
  };

  const { mutateAsync, isLoading } = useMutationHook(login, 'LOGIN');

  const _login = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    try {
      const { data } = await mutateAsync(formData);
      if (data.status === "failed" && data.description === 'Account not verified!') {
        navigate(`/otp/${formData.email}`);
        await requestOtp({ email: formData.email });
      }

      if (data.status === 'success') {
        sessionStorage.setItem('access_token', data.access_token);
        sessionStorage.setItem('refresh_token', data.refresh_token);
        sessionStorage.setItem('is_login', 'true');
        setUserStatus(true);
        setUserRole('seller');
        setUser(data.data);
        navigate(`/seller/dashboard`);
      }
    } catch (error: any) {
      if (error?.response?.data?.description === 'Account not verified') {
        navigate(`/otp/${formData.email}`);
        await requestOtp({ email: formData.email });
      }
    }
  };

  const disabled = !formData.email || !formData.password || !!validators.email(formData.email);

  return (
    <AuthContainer>
      <div>
        <Spacer height="4rem" />

        <h1>Log in to your account</h1>
        <p className="option">
          Don't have an account? <span onClick={() => navigate(`${SIGN_UP}`)}> Create an account</span>
        </p>

        <Container>
          <form className="sign-up">
            <Spacer height="1.5rem" />
            <InputCon>
              <span>Email Address</span>
              <InputField
                type="email"
                name="email"
                onChange={onUpdateFormData}
                placeholder="example@mail.com"
                extraStyles={extraStylesInput}
                required={true}
                error={formError.email}
                autoComplete="username"
              />
            </InputCon>

            <Spacer height="1.5rem" />
            <InputCon>
              <span>Password</span>
              <InputField
                type={showPassword}
                name="password"
                onChange={onUpdateFormData}
                placeholder="enter your password"
                extraStyles={extraStylesInput}
                // required={true}
                // error={formError.password}
                action={
                  <TogglePassword onClick={onTogglePassword}>
                    {showPassword !== 'text' ? <ShowPassword /> : <HidePassword />}
                  </TogglePassword>
                }
                autoComplete="new-passowrd"
              />
            </InputCon>

            <Spacer height="2rem" />

            <a href="/forgot-password" className="forgot">
              Forgot Password?
            </a>

            <Spacer height="2rem" />

            <div className="buttonContainer">
              <Button disabled={disabled} isLoading={isLoading} type="submit" onClick={_login}>
                LOGIN
              </Button>
            </div>
          </form>
        </Container>
      </div>
    </AuthContainer>
  );
};

export default SellerLogin;
