import { useEffect, useState } from 'react';

export const useCounter = () => {
  const [counter, setCounter] = useState(5);

  useEffect(() => {
    let timer: NodeJS.Timer;
    if (counter > 0) {
      timer = setTimeout(() => {
        setCounter(counter => counter - 1);
      }, 1000);
    }

    return () => clearTimeout(timer);
  }, [counter]);

  return { counter, setCounter };
};
