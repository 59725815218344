import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { HOME, LOGIN } from '../../navigation/routes';
import { userIsLoggedIn, userRoleAtom } from '../../store/atoms/userAtom';
import PageLayout from '../../components/layout/PageLayout';
import AuthLayout from '../../components/layout/AuthLayout';

const SellerProtectedRoute: React.FC<{ authRequired?: boolean }> = ({ authRequired }) => {
  const isLogged = useRecoilValue(userIsLoggedIn);
  const userRole = useRecoilValue(userRoleAtom);

  

  if (authRequired && userRole.includes('seller')) {
    return true ? (
      <PageLayout>
        <Outlet />
      </PageLayout>
    ) : (
      <Navigate to={`/seller${LOGIN}`} />
    );
  }

  return !isLogged ? (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  ) : (
    <Navigate to={HOME} />
  );
};

export default SellerProtectedRoute
