import axios, { AxiosError, AxiosResponse } from 'axios';

// Create a new Axios instance
const api = axios.create({
  // Your API base URL
  baseURL: process.env.REACT_APP_BASE_URL
});

console.log('My Base URL:', process.env.REACT_APP_BASE_URL);

// Add an interceptor to update the Authorization header
api.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem('access_token');
    if (token) {
      // If a token is present in sessionStorage, set the Authorization header
      config.headers.Authorization = 'Bearer ' + token;
    }
    // config.Authorization = 'application/json';
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response: any): Promise<AxiosResponse> => {
    return response;
  },
  (error: AxiosError): Promise<AxiosError> => {
    if (error?.response?.status === 401 && window.location.pathname !== '/sign-in') {
      sessionStorage.removeItem('access_token');
      window.location.href = '/auth/login';
    }
    return Promise.reject(error);
  }
);

export default api;
