import { styled } from '@mui/material';
import { COLORS } from '../../../theme/color';
import { breakpoints } from '../../../theme/theme';

export const MessageCon = styled('div')`
  display: flex;
  margin-bottom: 32px;
  .icon-con {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    background: #eaeefa;
    border-radius: 50%;
    margin-right: 16px;
    color: ${COLORS.MainBlue};
  }
  .content {
    width: 100%;
    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: ${COLORS.MainBlack};
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      gap: 40px;
    }
    .time-md {
      display: none;
    }
    p {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      color: ${COLORS.SecBlack};
      a,
      a:active,
      a:visited,
      a:hover {
        color: ${COLORS.MainBlue};
        font-weight: 500;
      }
    }
  }
  .time {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: ${COLORS.SecBlack};
    flex-shrink: 0;
  }
  @media (max-width: 1100px) {
    .time {
      display: none;
    }
    .icon-con {
      width: 40px;
      height: 40px;
    }
    .content {
      .title {
        margin-bottom: 10px;
      }
      .time-md {
        display: inline-block;
        font-size: 11px;
      }
    }
  }
  @media (max-width: ${breakpoints.sm}px) {
    .icon-con {
      transform: scale(0.85);
      margin-right: 12px;
    }
  }
`;
