import { styled } from '@mui/material';

export const PreferenceCON = styled('div')`
  h3 {
    color: #000;
    font-family: Outfit;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding-bottom: 20px;
  }
`;

export const PreferenceContentCON = styled('div')`
  padding-top: 47px;
  display: flex;
  gap: 30px;
  flex-direction: column;

  div {
    p {
      font-size: 18px;
    }
  }

  p {
    color: rgba(0, 0, 0, 0.7);
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
  }
`;
