// import { BrokenUserIcon, BrokenUserTickIcon } from '../../../assets/Icons';
import { extraButtonStyles } from './style';
import { ActionCON } from '../../Product/style';
import { Button } from '../../../components/atoms';
import { UserAction } from '../../../actions';
import { formatDate } from '../../../utils/dateFormater';
import { SellerUserAction } from '../../../actions/Seller/User';
import { SubAdminAction } from '../../../actions/Subadmin';

export const filter = [
  {
    label: 'Customer Supports',
    value: 'Customer Supports'
  },
  {
    label: 'Sub Admins',
    value: 'Sub Admins'
  },

  {
    label: 'Accountants',
    value: 'Accountants'
  }
];

export const verifiedShoppers = [
  {
    id: 1,
    field: 'email',
    headerName: 'Email Address',
    flex: 2,
    minWidth: 200
  },

  // {
  //   id: 2,
  //   field: 'role',
  //   headerName: 'Role',
  //   flex: 1,
  //   minWidth: 200
  // },

  {
    id: 2,
    field: 'location',
    headerName: 'Location',
    flex: 2,
    minWidth: 200
  },

  {
    id: 3,
    field: 'date',
    headerName: 'Date Joined',
    flex: 2,
    minWidth: 100,
    renderCell: ({ row }: any) => <p>{formatDate(row.dateJoined)}</p>
  },

  {
    id: 4,
    field: 'action',
    headerName: 'Action',
    flex: 0,
    minWidth: 100,
    renderCell: ({ row }: any) => {
      return <SubAdminAction row={row} />;
    }
  }
];

export const subAdminColumns = [
  {
    id: 1,
    field: 'email',
    headerName: 'Email Address',
    flex: 2,
    minWidth: 200
  },

  {
    id: 2,
    field: 'role',
    headerName: 'Role',
    flex: 1,
    minWidth: 200
  },

  {
    id: 3,
    field: 'location',
    headerName: 'Location',
    flex: 2,
    minWidth: 200
  },

  {
    id: 4,
    field: 'date',
    headerName: 'Date Joined',
    flex: 2,
    minWidth: 100,
    renderCell: ({ row }: any) => <p>{formatDate(row.dateJoined)}</p>
  },

  {
    id: 5,
    field: 'action',
    headerName: 'Action',
    flex: 0,
    minWidth: 100,
    renderCell: ({ row }: any) => {
      return <SubAdminAction row={row} />;
    }
  }
];

export const accountColumn = [
  {
    id: 1,
    field: 'email',
    headerName: 'Email Address',
    flex: 2,
    minWidth: 100
  },

  {
    id: 2,
    field: 'location',
    headerName: 'Location',
    flex: 2,
    minWidth: 100
  },

  {
    id: 3,
    field: 'date',
    headerName: 'Date Joined',
    flex: 2,
    minWidth: 100,
    renderCell: ({ row }: any) => <p>{formatDate(row.dateJoined)}</p>
  },

  {
    id: 4,
    field: 'action',
    headerName: 'Action',
    flex: 0,
    minWidth: 100,
    renderCell: ({ row }: any) => {
      return <SubAdminAction row={row} />;
    }
  }
];

export const unverifiedShoppers = [
  {
    id: 1,
    field: 'businessName',
    headerName: 'Name',
    flex: 3,
    minWidth: 300
  },

  // {
  //   id: 2,
  //   field: 'phoneNumber',
  //   headerName: 'Phone Number',
  //   flex: 1,
  //   minWidth: 200
  // },

  {
    id: 2,
    field: 'email',
    headerName: 'Email Address',
    flex: 2,
    minWidth: 200
  },

  // {
  //   id: 4,
  //   field: 'productCategory',
  //   headerName: 'Product Category',
  //   flex: 1,
  //   minWidth: 100
  // },

  {
    id: 3,
    field: 'date',
    headerName: 'Date Joined',
    flex: 2,
    minWidth: 100,
    renderCell: ({ row }: any) => <p>{formatDate(row.dateJoined)}</p>
  },

  {
    id: 4,
    field: 'action',
    headerName: 'Action',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => {
      return <SubAdminAction row={row} />;
    }
  }
];

export const verifiedBusinessColumn = [
  {
    id: 1,
    field: 'name',
    headerName: 'Name',
    flex: 3,
    minWidth: 300
  },

  {
    id: 2,
    field: 'address',
    headerName: 'Address',
    flex: 1,
    minWidth: 200
  },

  {
    id: 3,
    field: 'emailAddress',
    headerName: 'Email Address',
    flex: 1,
    minWidth: 100
  },

  {
    id: 4,
    field: 'cacReg',
    headerName: 'CAC Registration Number',
    flex: 1,
    minWidth: 100
  },

  {
    id: 8,
    field: 'action',
    headerName: 'Action',
    flex: 0,
    minWidth: 100,
    renderCell: ({ row }: any) => {
      return <SubAdminAction row={row} />;
    }
  }
];

export const unverifiedBusinessColumn = [
  {
    id: 1,
    field: 'name',
    headerName: 'Name',
    flex: 3,
    minWidth: 300
  },

  {
    id: 2,
    field: 'address',
    headerName: 'Address',
    flex: 1,
    minWidth: 200
  },

  {
    id: 3,
    field: 'emailAddress',
    headerName: 'Email Address',
    flex: 1,
    minWidth: 100
  },

  {
    id: 4,
    field: 'cacReg',
    headerName: 'CAC Registration Number',
    flex: 1,
    minWidth: 200
  },

  {
    id: 8,
    field: 'action',
    headerName: 'Action',
    flex: 1,
    minWidth: 100,
    // eslint-disable-next-line no-empty-pattern
    renderCell: ({}: any) => (
      <ActionCON>
        <Button extraStyles={extraButtonStyles}>View</Button>
      </ActionCON>
    )
  }
];
