import React from 'react';
import { UploadIcon2, DeleteCartProduct } from '../../../assets/Icons';

type ImageDropzoneProps = {
  inputRef: React.RefObject<HTMLInputElement> | null;
  handleFileRead: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleFileDelete: (e: any) => void;
  small?: boolean;
  image: string | File | null;
};

const DisplayImage = ({
  image,
  handleFileDelete
}: {
  image: string | File | null;
  handleFileDelete: (e: any) => void;
}) => {
  if (image instanceof File) {
    // It's a File object
    return (
      <div className="image">
        <button className="delete-icon" onClick={handleFileDelete} type="button">
          <DeleteCartProduct />
        </button>
        <img src={URL.createObjectURL(image)} width={150} alt={`Product`} />
      </div>
    );
  } else if (typeof image === 'string') {
    // It's a string URL
    return (
      <div className="image">
        <button className="delete-icon" onClick={handleFileDelete} type="button">
          <DeleteCartProduct />
        </button>
        <img src={image} width={150} alt={`Product`} />
      </div>
    );
  }
  return null;
};

function ImageDropzone({ inputRef, handleFileRead, handleFileDelete, small, image }: ImageDropzoneProps) {
  return (
    <div className="image-upload-con">
      {image ? (
        <DisplayImage image={image} handleFileDelete={handleFileDelete} />
      ) : (
        <>
          <div className={`${small ? 'image-ref-sm' : 'image-ref'}`} onClick={() => inputRef?.current?.click()}>
            <UploadIcon2 />
            <p>Select an Image to upload</p>
          </div>
          <input
            ref={inputRef}
            onChange={handleFileRead}
            type="file"
            name="productImage1"
            multiple
            hidden
            required={true}
          />
        </>
      )}
    </div>
  );
}

export default ImageDropzone;
