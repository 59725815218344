import { styled,  } from '@mui/material';
import { COLORS } from '../../theme/color';
import { breakpoints } from '../../theme/theme';

export const OrderCON = styled('div')``;

export const OrderTableCON = styled('div')`
  background: ${COLORS.White};
`;

export const TableHeaderCON = styled('div')`
  padding: 32px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3{
    color: ${COLORS.MainBlack};
  }
  @media (max-width: ${breakpoints.sm}px) {
    padding: 32px 10px;
    h3{
      
      flex: 1;
      font-size: 14px;

    }
    
  }
`;



export const extraButtonStyles = {
  color: `${COLORS.MainBlue}`,
  backgroundColor: '#EFF1FB',
  borderRadius: '12px',
  fontSize: '14px',
  fontWeight: '400',
  height: '31px',
  width: '102px'
};


export const ActionCON = styled('div')`
  cursor: pointer;
`;

