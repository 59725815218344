import styled from '@emotion/styled';
import { Switch } from '@mui/material';
// import { FormControlLabel, FormGroup, Switch } from '@mui/material';

type customSwitchProps = {
  checked: boolean;
  label: string;
  handleChange: () => void;
};

const SwitchCON = styled('div')`
  display: flex;
  gap: 20px;
  align-items: center;

  .label {
    color: #000;
    font-size: 23px;
    font-weight: 500;
    line-height: 24px;
  }
`;

export const CustomSwitch = ({ checked, handleChange, label }: customSwitchProps) => {
  return (
    <SwitchCON>
      {label && <p className="label">{label}</p>}
      <Switch checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
    </SwitchCON>
  );
};
