import { enqueueSnackbar } from 'notistack';

const MESSAGE = {
  LOGIN: {
    success: 'Login successful',
    error: 'Failed, Try Again.'
  },
  LOGOUT: {
    success: 'Logout successful',
    error: ''
  },
  FORGOT_PASSWORD: {
    success: 'Password reset link sent to your email',
    error: 'Failed, Try Again.'
  },
  CONFIRM_PASSWORD_RESET: {
    success: 'Password reset successful',
    error: 'Failed, Try Again.'
  },
  SIGNUP: {
    success: 'Seller Account created',
    error: 'Failed, Try Again.'
  },
  OTP: {
    success: 'Email is verified',
    error: 'OTP Not Found or Expired'
  },
  UPDATE_PROFILE: {
    success: 'Profile updated',
    error: 'Failed, Try Again.'
  },
  CHANGE_PASSWORD: {
    success: 'Password changed successfully',
    error: 'Failed, Try Again.'
  },
  NEW_ADDRESS: {
    success: 'Address added',
    error: 'Failed, Try Again.'
  },
  UPDATE_ADDRESS: {
    success: 'Address updated',
    error: 'Failed, Try Again.'
  },
  PRODUCT_UPLOAD: {
    success: 'Product Uploaded Successfully',
    error: 'Product Upload Failed'
  },
  UPDATE_PRODUCT: {
    success: 'Product Updated Successfully',
    error: 'Product Update Failed'
  },
  ADD_NEW_USER: {
    success: 'New User Added Successfully',
    error: 'Failed, Try Again.'
  },
  UPDATE_USER_ROLE: {
    success: 'User Role Updated Successfully',
    error: 'Failed, Try Again.'
  },
  REMOVE_SUB_ADMIN: {
    success: 'Sub Admin Removed',
    error: 'Failed, Try Again.'
  },
  ADD_CATEGORY: {
    success: 'Category Added',
    error: 'Failed, Try Again.'
  },
  UPDATE_CATEGORY: {
    success: 'Category Updated Successfully',
    error: 'Category Update Failed'
  },
  ADD_ROLE: {
    success: 'Role Added',
    error: 'Failed, Try Again.'
  },
  UPDATE_ROLE: {
    success: 'Role Updated',
    error: 'Failed, Try Again.'
  },
  ADD_LOGISTICS: {
    success: 'Logistics Added',
    error: 'Failed, Try Again.'
  },
  UPDATE_LOGISTICS: {
    success: 'Logistics Updated',
    error: 'Failed, Try Again.'
  },
  DELETE_LOGISTICS: {
    success: 'Logistics Deleted',
    error: 'Failed, Try Again.'
  },
  VERIFY_SELLER: {
    success: 'Seller verified successfully',
    error: 'Unable to verify seller'
  },
  ROLE_CHANGED: {
    success: 'Role Changed',
    error: 'Failed, Try Again.'
  },
  CANCEL_ORDER: {
    success: 'Order Canceled',
    error: 'Failed, Try Again.'
  },
  UPLOAD_SELLER_LOGO: {
    success: 'Logo Uploaded',
    error: 'Failed, Try Again.'
  },
  ADD_FUND_WALLET: {
    success: 'Wallet funded',
    error: 'Failed, Try Again.'
  },
  ADD_BANK_DETAILS: {
    success: 'Bank Details Added',
    error: 'Failed, Try Again.'
  },
  DELETE_BANK_DETAILS: {
    success: 'Bank Details Deleted',
    error: 'Failed, Try Again.'
  },
  DELETE_ACCOUNT: {
    success: 'Account Deleted',
    error: 'Failed, Try Again.'
  },
  INITIATE_WALLET_WITHDRAWAL: {
    success: 'Withdrawal Initiated, Enter OTP to confirm.',
    error: 'Failed, Try Again.'
  },
  CONFIRM_WALLET_WITHDRAWAL: {
    success: 'Your withdrawal request has been successfully sent. Pending admin approval.',
    error: 'Failed, Try Again.'
  },
};

export type MessageProp = keyof typeof MESSAGE;

export const apiResponse = (data: Record<string, any> | null, type: 'success' | 'error' , operation?: MessageProp) => {
  const result = {
    success: data?.data?.description || data?.description,
    error: data?.response?.data?.description || data?.response?.data?.data?.description
  };

  const message = result[type] || (operation && MESSAGE[operation][type]);

  if (!message) return;

  enqueueSnackbar(message, {
    variant: data?.data?.status === 'failed' ? 'error' : type,
    autoHideDuration: type === 'error' ? 2500 : 2000
  });
};
