import { atom } from 'recoil';
import { STORE_KEYS } from '../keys';

export const deliveryStatusFilter = atom<string>({
  key: STORE_KEYS.DELIVERY_STATUS_FILTER,
  default: 'Delivered'
});

export const deliveryRequestActionPopover = atom<HTMLElement | null>({
  key: STORE_KEYS.DELIVERY_ACTION_POPOVER,
  default: null
});
