import { useRef, useState } from 'react';
import { UploadIcon2 } from '../../../../assets/Icons';
import { Button, Spacer } from '../../../../components/atoms';
import { Modal } from '../../../../components/molecules';
import { CsvUploadCon, ImageUploadCON } from './style';
import { COMMONTYPES } from '../../../../TYPES/event.types';
import { useMutationHook } from '../../../../network/mutations/useMutationHook';
import { csvUpload } from '../../../../network/services/goods';
import { useQueryClient } from 'react-query';
type CsvProductUploadProp = {
  onModalClose: () => void;
};

export const CsvProductUpload = ({ onModalClose }: CsvProductUploadProp) => {
  const [formData, setFormData] = useState<{file: any }>({file: null});
  const imageUploadRef = useRef<HTMLInputElement | null>(null);
  const [selectedFile, setFile] = useState<string | undefined>('');
  const { mutateAsync, isLoading } = useMutationHook(csvUpload, 'PRODUCT_UPLOAD');
  const queryClient = useQueryClient();

  const _csvUpload = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    try {
     if(formData.file != null){
      const { data } = await mutateAsync(formData);
      if (data.status === 'success') {
        queryClient.invalidateQueries('seller_products');
      }
     }
    } catch (error: any) {}
  };

  const handleFileRead = async (e: COMMONTYPES['inputChange']) => {
    const file: any = e.target.files?.[0];
    setFile(file?.name)
    if (file) {
      setFormData(file)
    }
  };

  const disabled = formData.file === null

  return (
    <Modal maxWidth="500px" title="Upload CSV" onModalClose={onModalClose}>
      <CsvUploadCon>
        <ImageUploadCON>
          <div onClick={() => imageUploadRef.current?.click()}>
            <UploadIcon2 />
            <p  >Select an Image to upload</p>
            <Spacer height="20px" />
            {
              selectedFile != null && <p className='selected-file'>{selectedFile}</p>
            }
          </div>
          <input   accept=".csv" onChange={handleFileRead} ref={imageUploadRef} type="file" name="" hidden required={true} />
        </ImageUploadCON>
        <Spacer height="30px" />
        <Button isLoading={isLoading} disabled={disabled} onClick={_csvUpload} extraStyles={{ borderRadius: '12px' }}>Upload</Button>
      </CsvUploadCon>
    </Modal>
  );
};
