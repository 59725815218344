import { useRecoilState } from 'recoil';
import { Action, CloseCircleIcon, TickCircleIcon } from '../../assets/Icons';
import { CustomPopover } from '../../components/atoms';
import { ActionCON } from '../style';
import { deliveryRequestActionPopover } from '../../store/atoms/deliveryAtom';

export const DeliveryRequestAction = () => {
  const [value, setValue] = useRecoilState(deliveryRequestActionPopover);

  const handleOpen = (event: any) => {
    if (value != null) {
      setValue(null);
    } else {
      setValue(event.currentTarget);
    }
  };

  const options = [
    {
      label: 'Accept Request',
      value: 'Accept Request',
      Icon: TickCircleIcon,
      color: '#27AE60',
      handleAction: () => {}
    },

    {
      label: 'Decline Request',
      value: 'Decline Request',
      Icon: CloseCircleIcon,
      color: '#D90429',
      handleAction: () => {}
    }
  ];
  return (
    <ActionCON aria-describedby="order-actions" onClick={event => handleOpen(event)}>
      <Action />
      <CustomPopover id="order-actions" open={value} options={options} />
    </ActionCON>
  );
};
