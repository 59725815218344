import {
  GET_SELLER_DASHBOARD_OVERVIEW,
  GET_SELLER_ORDERS,
  GET_SELLER_ORDERS_AS_CSV,
  GET_SELLER_ORDER_ANALYTICS,
  GET_SELLER_ORDER_STATISTICS,
  GET_SELLER_OVERVIEW_ORDERS,
  GET_SELLER_SALES_STATISTICS,
  GET_SELLER_TOTAL_PROFITS_STATISTICS
} from '../config/endpoints';
import api from '../config/setAuthHeader';

export const getSellerOrders = async ({ queryKey }: any) => {
  const [_, payload] = queryKey;
  const { page, size } = payload;
  const { data } = await api.get(`${GET_SELLER_ORDERS}?page=${page}&pageSize=${size}`);
  return data;
};

export const getSellerOrdersAnalytics = async () => {
  const { data } = await api.get(`${GET_SELLER_ORDER_ANALYTICS}`);
  return data;
};

export const getSellerDashboardOverview = async () => {
  const { data } = await api.get(`${GET_SELLER_DASHBOARD_OVERVIEW}`);
  return data;
};

export const getSellerOverviewOrders = async ({ queryKey }: any) => {
  const [_, payload] = queryKey;
  const { orderType, page, size } = payload;
  const { data } = await api.get(`${GET_SELLER_OVERVIEW_ORDERS}${orderType}?page=${page}&size=${size}&sort=id,desc`);
  return data;
};

export const sellerOrdersStatistics = async ({ queryKey }: any) => {
  const [_, payload] = queryKey;
  const { exportDate } = payload;
  const { data } = await api.get(`${GET_SELLER_ORDER_STATISTICS}?dateTime=${exportDate}`);
  return data;
};

export const sellerSalesStatistics = async ({ queryKey }: any) => {
  const [_, payload] = queryKey;
  const { exportDate } = payload;
  const { data } = await api.get(`${GET_SELLER_SALES_STATISTICS}?dateTime=${exportDate}`);
  return data;
};

export const sellerTotalProfitsStatistics = async ({ queryKey }: any) => {
  const [_, payload] = queryKey;
  const { exportDate } = payload;
  const { data } = await api.get(`${GET_SELLER_TOTAL_PROFITS_STATISTICS}?dateTime=${exportDate}`);
  return data;
};

export const getSellerOrdersAsCsv = async () => {
  const data = await api.get(`${GET_SELLER_ORDERS_AS_CSV}`);
  return data;
};
