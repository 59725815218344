import { FormControl } from '@mui/material';
import {  CustomMenuItem, CustomSelect } from './style';

type SelectFieldProps = {
  options?: any[];
  value: string  ;
  handleChange?: any;
};

export const SelectField = ({ options, value, handleChange }: SelectFieldProps) => {
  return (
    <FormControl>
      <CustomSelect onChange={handleChange} value={value}>
        {options?.map(({ label, value }) => (
          <CustomMenuItem key={label} value={value}>
            {label}
          </CustomMenuItem>
        ))}
      </CustomSelect>
    </FormControl>
  );
};
