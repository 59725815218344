import CssBaseline from '@mui/material/CssBaseline/CssBaseline';
import responsiveFontSizes from '@mui/material/styles/responsiveFontSizes';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RecoilRoot } from 'recoil';
import { App } from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { themes } from './theme/theme';

const theme = responsiveFontSizes(themes);

// const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
// const queryClient = new QueryClient({
//   defaultOptions: {
//     queries: {
//       refetchOnWindowFocus: false,
//       refetchOnMount: false,
//       refetchOnReconnect: false,
//       retry: false,
//       staleTime: twentyFourHoursInMs,
//       cacheTime: twentyFourHoursInMs
//     }
//   }
// });

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const anchorOrigin = { vertical: 'top', horizontal: 'right' } as const;

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider maxSnack={3} preventDuplicate anchorOrigin={anchorOrigin}>
            <App />
          </SnackbarProvider>
        </ThemeProvider>
      </RecoilRoot>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
