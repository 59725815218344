import { useRecoilState } from 'recoil';
import { Button } from '../../components/atoms';
import { supportAction } from '../../store/atoms/customerAtom';
import { ComplaintBox, StatusCON, UserDetailsCON, UserDetailsContent } from './style';
import { ActionCON, extraSubmitBtnStyles } from '../style';
import { Modal } from '../../components/molecules';
import { GreenDot } from '../../assets/Icons';

export const SupportAction = ({ extraButtonStyles }: any) => {
  const [value, setValue] = useRecoilState(supportAction);

  const handleClose = () => {
    setValue(false);
  };

  return (
    <ActionCON>
      <Button onClick={() => setValue(true)} extraStyles={extraButtonStyles}>
        View
      </Button>
      {value && (
        <Modal maxWidth="400px" title="Dispute Details" onModalClose={handleClose}>
          <UserDetailsCON>
            <UserDetailsContent>
              <p className="label">User Email</p>
              <p className="value">eleanorpena@gmail.com</p>
            </UserDetailsContent>
            <UserDetailsContent>
              <p className="label">Date</p>
              <p className="value">7/03/22 08:00pm</p>
            </UserDetailsContent>
            <UserDetailsContent>
              <p className="label"> Status</p>
              <StatusCON status="Pending">
                <GreenDot /> <p>Pending</p>
              </StatusCON>
            </UserDetailsContent>
            <ComplaintBox>
              <p className="label">Complaint</p>
              <div>
                <p>
                  I want to request for a refund, the product i was delivered was a used product. I can’t use it that
                  way, please refund me
                </p>
              </div>
            </ComplaintBox>
            <Button extraStyles={extraSubmitBtnStyles}>Reply Customer</Button>
          </UserDetailsCON>
        </Modal>
      )}
    </ActionCON>
  );
};
