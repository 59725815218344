/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Collapse, ListItem } from '@mui/material';
import React from 'react';
import { Logo, NavLinks, Spacer } from '../../atoms';
import { NavCon } from './style';
import { navLinkProps } from '../../atoms/NavLinks/NavLinks';
import { useRecoilState, useSetRecoilState } from 'recoil';
// import { useRecoilState, useRecoilValue } from 'recoil';
import { userIsLoggedIn, userRoleAtom } from '../../../store/atoms/userAtom';
import { logout } from '../../../network/services/auth';
import { LogoutIcon } from '../../../assets/Icons';
import { apiResponse } from '../../../network/config/message.constants';
import { useNavigate } from 'react-router-dom';

type SideNavType = {
  navs: navLinkProps[];
  signout?: boolean;
  disable?: boolean;
};

export const SideNav = ({ navs, signout, disable }: SideNavType) => {
  const [checked, setChecked] = React.useState(true);
  const [isLogged, setUserStatus] = useRecoilState(userIsLoggedIn);
  const [userRole, setUserRole] = useRecoilState(userRoleAtom);
  const setUser = useSetRecoilState(userRoleAtom);

  const navigate = useNavigate();

  // const handleChange = () => {
  //   setChecked(prev => !prev);
  // };

  const _logout = async () => {
    try {
      const data = await logout();
      apiResponse(data, 'success', 'LOGOUT');
    } catch (error) {
      console.log(error);
    }
    setUserRole('');
    setUserStatus(false);
    setUser('')

    sessionStorage.clear();
    userRole === 'admin' && navigate('/admin');
    userRole === 'seller' && navigate('/');
  };

  return (
    <NavCon>
      <Collapse
        orientation="horizontal"
        in={checked}
        timeout="auto"
        collapsedSize={42}
        sx={{
          '& .MuiCollapse-wrapper': { minWidth: '100%' },
          '& .MuiCollapse-wrapperInner': { minWidth: '100%' }
        }}>
        <Box component="div" sx={{ minWidth: 'max-content' }}>
          <div className="menu">
            {!disable && (
              <>
                <Logo />
                <Spacer width="1.3rem" />
              </>
            )}
          </div>

          <div className="nav_bar">
            {navs.map((nav, i) => (
              <ListItem key={i} disablePadding>
                <NavLinks {...nav} minimize={true} />
              </ListItem>
            ))}

            <ListItem>
              <div className="logout" onClick={_logout}>
                <LogoutIcon />
                <p>Log Out</p>
              </div>
            </ListItem>
          </div>
        </Box>
      </Collapse>
    </NavCon>
  );
};
