export type ValidationType = Record<string, (...args: (string | any)[]) => string | undefined>;

const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const SPECIAL_CHARACTER = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

export const validators: ValidationType = {
  email: (email: string) => {
    if (!email) {
      return 'E-mail is required';
    }

    if (!EMAIL_PATTERN.test(email)) {
      return 'E-mail is invalid';
    }
  },

  lastname: (name: string) => {
    if (!name) return 'lastname is required';
  },
  business_address: (address: string) => {
    if (!address) return 'business address is required';
  },

  business_name: (name: string) => {
    if (!name) return 'business name is required';
  },

  cac: (cac: string) => {
    if (!cac) return 'cac is required';
  },



  firstname: (name: string) => {
    if (!name) return 'firstname is required';
  },

  address: (name: string) => {
    if (name === '') return 'address is required';
  },
  city: (name: string) => {
    if (name === '') return 'city is required';
  },
  street: (name: string) => {
    if (name === '') return 'street is required';
  },

  role: (selected: string) => {
    if (selected === '') {
      return 'Select a role';
    }
  },

  fullName: (name: string) => {
    if (name === '') return 'Name is required';

    // if (!/^[^\s]+( [^\s]+)+$/.test(name)) return "Invalid name";

    // if (name.length < 5) return "Name is too short";
  },
  name: (name: string) => {
    if (name === '') return 'Name is required';

    // if (!/^[^\s]+( [^\s]+)+$/.test(name)) return "Invalid name";

    // if (name.length < 5) return "Name is too short";
  },
  description: (name: string) => {
    if (name === '') return 'Description is required';

    // if (!/^[^\s]+( [^\s]+)+$/.test(name)) return "Invalid name";

    // if (name.length < 5) return "Name is too short";
  },
  quantity: (name: number) => {
    if (name < 1) return 'Quantity is required';

    // if (!/^[^\s]+( [^\s]+)+$/.test(name)) return "Invalid name";

    // if (name.length < 5) return "Name is too short";
  },
  price: (name: number) => {
    if (name < 1) return 'Price is required';
  },
  weight: (name: number) => {
    if (name < 1) return 'Weight is required';
  },

  phoneNumber: (number: string) => {
    if (number.length === 0) {
      return 'Phone number is required';
    }
  },
  country: (number: string) => {
    if (number.length === 0) {
      return 'Country is required';
    }
  },
  accountName: (name: string) => {
    if (name === '') return 'Account Name is required';
  },
  accountNumber: (number: string) => {
    if (number.length === 0) {
      return 'Account Number is required';
    }
    if (number.length < 10) {
      return 'Account number cannot be less than 10 digits';
    }
    if (!/^\d+$/.test(number)) {
      return 'Account Number is invalid';
    }
    if (number.length > 10) {
      return 'Account number cannot be more than 10 digits';
    }
  },
  productImage: (name: string[]) => {
    if (name.length === 0) {
      return 'Product Image is required';
    }
  },

  productWeightImage: (name: string) => {
    if (name === '') return 'Product Weight Image is required';
  },

  videoUrl: (name: string) => {
    if (name === '') return 'Product VideoUrl Image is required';
  },
  referralPhoneNumber: (number: string) => {
    if (number.length === 0) {
      return 'Number is required';
    }
  },

  otp: (number: string) => {
    if (number.length === 0) return 'OTP is required';
    if (!/^\d{4}$/.test(number)) return 'Enter four digit number';
  },

  password: (password: string) => {
    if (password.length === 0) return 'Password is required';
    if (password.length <= 8) return 'Password must be greater than 8 digits';
    if (!/[A-Z]/.test(password)) return 'Password must include at least one uppercase letter';
    if (!/[a-z]/.test(password)) return 'Password must include at least one lowercase letter';
    if (!/[0-9]/.test(password)) return 'Password must include at least one number';
    if (!SPECIAL_CHARACTER.test(password)) return 'Password must include at least one special character';
  },

  confirm_password: (confirmNewPassword: string) => {
    if (confirmNewPassword.length === 0) return 'Password is required';
    if (confirmNewPassword.length <= 8) return 'Password is must be greater than 8';
    if (!/[A-Z]/.test(confirmNewPassword)) return 'Password must include at least one uppercase letter';
    if (!/[a-z]/.test(confirmNewPassword)) return 'Password must include at least one lowercase letter';
    if (!/[0-9]/.test(confirmNewPassword)) return 'Password must include at least one number';
    if (!SPECIAL_CHARACTER.test(confirmNewPassword)) return 'Password must include at least one special character';
  },

  old_password: (confirmNewPassword: string) => {
    if (confirmNewPassword.length === 0) return 'Password is required';
    if (confirmNewPassword.length <= 8) return 'Password is must be greater than 8';
    if (!/[A-Z]/.test(confirmNewPassword)) return 'Password must include at least one uppercase letter';
    if (!/[a-z]/.test(confirmNewPassword)) return 'Password must include at least one lowercase letter';
    if (!/[0-9]/.test(confirmNewPassword)) return 'Password must include at least one number';
    if (!SPECIAL_CHARACTER.test(confirmNewPassword)) return 'Password must include at least one special character';
  },

  newPassword: (newPassword: string) => {
    if (newPassword.length === 0) return 'Password is required';
    if (newPassword.length <= 8) return 'Password is must be greater than 8';
    if (!/[A-Z]/.test(newPassword)) return 'Password must include at least one uppercase letter';
    if (!/[a-z]/.test(newPassword)) return 'Password must include at least one lowercase letter';
    if (!/[0-9]/.test(newPassword)) return 'Password must include at least one number';
    if (!SPECIAL_CHARACTER.test(newPassword)) return 'Password must include at least one special character ';
  }
};

const validateFormSubmit = (formData: Record<string, string>) => {
  let errors: any = {};
  let isValid = true;

  Object.keys(formData).forEach(key => {
    const error = validators[key](formData[key], formData);
    errors[key] = error;
    if (error) {
      isValid = false;
    }
  });

  return { errors, isValid };
};

export const checkFormData = (formData: Record<string, any>, setFormError?: any) => {
  const { isValid, errors } = validateFormSubmit(formData);
  console.log(errors);
  if (setFormError) {
    setFormError(errors);
  }
  return isValid;
};

export const isEmptyFormField = (objects: Record<string, any>) => {
  const value = Object.values(objects).includes('');
  return value;
};

export const isAmountNumber = (value: string) => {
  return value.replace(/[^,.0-9/]|\//g, '');
};
