import { styled } from '@mui/material';
import { COLORS } from '../../../theme/color';

export const DisclosureCon = styled('div')`
  max-width: 485px;
  border-radius: 12px;
  overflow: hidden;
  background: #ffffff;
  button {
    width: 100%;
    min-height: 64px;
    border-radius: 12px;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    padding: 25px;
    outline: none;
    border: none;
    font-weight: 400;
    font-size: 16px;
    color: #483d3d;
    text-align: start;
    svg {
      margin-left: 20px;
    }
  }
  .content {
    padding: 25px;
    padding-top: 0;
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    color: #666666;
  }
`;

export const CustomerCon = styled('div')`
  max-width: 485px;
  height: 471px;
  background: ${COLORS.White};
  padding: 24px 28px;
  .header {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #483d3d;
    margin-bottom: 16px;
  }
  .desc {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    color: #666666;
  }
  .medium {
    display: flex;
    margin-top: 35px;
    svg {
      margin-right: 25px;
    }
    .head {
      font-weight: 500;
      font-size: 18px;
      line-height: 120.5%;
      color: #333333;
      margin-bottom: 8px;
    }
    .info {
      font-weight: 300;
      font-size: 14px;
      line-height: 120.5%;
      color: #483d3d;
    }
  }
`;
