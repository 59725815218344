import { truncateCharCount } from '../../../utils/truncate';
import { TableRowProductCardCon } from './styles';
// import { Theme, useMediaQuery } from '@mui/material';

type Iprop = {
  order: any;
};

export const TableRowProductCard = ({ order }: Iprop) => {
  //   const sm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <TableRowProductCardCon>
      {order?.productImage1 && <img src={order.productImage1} alt="product" />}
      <p>{truncateCharCount(order.productName, 20)}</p>
    </TableRowProductCardCon>
  );
};
