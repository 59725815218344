import { styled } from '@mui/material';
import { breakpoints } from '../../../theme/theme';

export const DashboardHeaderCON = styled('div')`
  width: 100%;

  .content {
    display: flex;

    align-items: center;
    justify-content: space-between;

    @media (max-width: ${breakpoints.md}px) {
      flex-direction: column;
      align-items: start;
      margin-top: 61px;
  
      justify-content: end;
      align-items: end;
    }
  }

  h2 {
    font-size: 24px;
    font-weight: 700px;
  }
`;
