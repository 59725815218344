import { styled } from "@mui/material";
import { BasicBox } from "../styles";
import { COLORS } from "../../../theme/color";

export const UserWalletCon = styled(BasicBox)`
  .wallet-stat-con {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.8%;
    overflow-x: scroll;
  }
  div.subhead {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: ${COLORS.MainBlack};
    padding: 48px 0 16px;
    border-bottom: 1px solid #ededed;
    margin-bottom: 24px;
  }
`;