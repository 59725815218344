import { useMutation } from 'react-query';
import { ResponseContent } from '../../TYPES/response.types';
import { MessageProp, apiResponse } from '../config/message.constants';

// Define type aliases
type DefaultPayloadType = any;
type DefaultResponseType = any;

// Use the type aliases as default types for the generic parameters
export const useMutationHook = <T = DefaultPayloadType, D = DefaultResponseType>(
  request: (payload: T) => Promise<ResponseContent<D>>,
  operation?: MessageProp,
  skipDefaultErrorHandling: boolean = false
) => {
  const mutate = useMutation(request, {
    onSuccess: async data => {
      apiResponse(data, 'success', operation);
      return;
    },
    onError: (error: Record<any, any>) => {
      if (!skipDefaultErrorHandling) {
        apiResponse(error, 'error', operation);
        return;
      }
    }
  });

  return mutate;
};
