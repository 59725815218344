import { HTMLMotionProps, motion } from 'framer-motion';
import React from 'react';
import { ContainerProps } from './Container';

export interface AnimatedContainerProps
  extends ContainerProps,
    Pick<HTMLMotionProps<'div'>, 'initial' | 'animate' | 'transition' | 'exit'> {
  key?: any;
}

const StyledContainer = {
  width: '100%'
};
export const AnimatedContainer: React.FunctionComponent<AnimatedContainerProps> = props => {
  const { children, extraStyles, transition, key, ...restProps } = props;
  const { initial = {}, animate = {}, exit = {} } = restProps;

  return (
    <motion.div
      key={key}
      initial={typeof initial === 'object' ? { height: 100, ...initial } : initial}
      animate={typeof animate === 'object' ? { height: 'auto', ...animate } : animate}
      exit={
        typeof exit === 'object'
          ? {
              overflow: 'hidden',
              height: 0,
              ...exit
            }
          : exit
      }
      transition={{ ease: 'easeInOut', duration: 0.2 }}
      style={{ ...StyledContainer, ...extraStyles }}>
      {children}
    </motion.div>
  );
};
