import { DashboardHeader, TopHeaderCard } from '../../../components/molecules';
import { AddIcon, AdminUsersIcon, BrokenBox, BrokenGclose, BrokenGraph } from '../../../assets/Icons';
import { columns } from './data';
import { ProductCON, ProductTableCON, TableHeaderCON } from './style';
import { ADD_PRODUCT } from '../../../navigation/routes';
import { CustomTable, Spacer } from '../../../components/atoms';
import { userRoleAtom } from '../../../store/atoms/userAtom';
import { useRecoilValue } from 'recoil';
import { useQuery } from 'react-query';
import { getProductsAnalytics, getSellerProducts } from '../../../network/services/goods';
import { useState } from 'react';

export const SellerProductList = () => {
  const userRole = useRecoilValue(userRoleAtom);
  const [page, setPage] = useState(1);
  const pageSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE ? parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE) : 10;
  const { data, isFetchedAfterMount } = useQuery(
    ['seller_products', { page: page - 1, size: pageSize }],
    getSellerProducts
  );
  const { data: productAnalyticsData } = useQuery('seller_products_analytics', getProductsAnalytics);
  const products = data || [];

  const topCardData = [
    {
      title: 'No of products',
      total: productAnalyticsData?.productCount ?? 0,
      Icon: BrokenGraph,
      iconBg: '#F5FBEF'
    },
    {
      title: 'Products Out of Stock',
      total: productAnalyticsData?.productOutOfStock ?? 0,
      Icon: BrokenBox,
      iconBg: '#FEF9EC'
    },

    {
      title: 'Total Customers',
      total: productAnalyticsData?.totalCustomers ?? 0,
      Icon: AdminUsersIcon,
      iconBg: '#EFF1FB'
    },

    {
      title: 'Cart Abandonment',
      total: productAnalyticsData?.abandonedCart ?? 0,
      Icon: BrokenGclose,
      iconBg: '#FFF0F3'
    }
  ];
  return (
    <ProductCON>
      <DashboardHeader
        title="Products"
        buttonPlaceholder="Add New Product"
        route={`/${userRole.includes('seller') ? 'seller' : 'admin'}${ADD_PRODUCT}`}
        Icon={AddIcon}
      />
      <TopHeaderCard data={topCardData} />
      <Spacer height="48px" />
      <ProductTableCON>
        <TableHeaderCON>
          <h3>Product list</h3>
        </TableHeaderCON>
        {
          <CustomTable
            isLoading={isFetchedAfterMount}
            columns={columns}
            data={products.data || []}
            totalItems={products.total_products ?? 0}
            totalPages={Math.ceil(products.total_products / pageSize) ?? 0}
            currentPage={page}
            setCurrentPage={setPage}
          />
        }
      </ProductTableCON>
    </ProductCON>
  );
};
