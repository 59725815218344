import { Theme, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { GraphIcon, BoxOrangeIcon, BoxBlueIcon, ProfileUserIcon, ClipboardCloseIcon } from '../../../assets/Icons';
import { CloudIcon, UploadIcon } from '../../../assets/Icons';
import { Button, CustomTable, Spacer } from '../../../components/atoms';
import { TabPanel, TopHeaderCard } from '../../../components/molecules';
import { newOrderColumn } from '../../Order/data';
import { a11yProps } from './data';
import { useQuery } from 'react-query';
import { saveAs } from 'file-saver';
import { enqueueSnackbar } from 'notistack';
import { ChartsCON, DashboardCON, DashboardTab, DashboardTableCON, DashboardTabs, TopContentCON } from './style';
import {
  getSellerDashboardOverview,
  getSellerOrdersAsCsv,
  getSellerOverviewOrders
} from '../../../network/services/orders';
import SalesReportChart from '../../../components/molecules/Charts/SalesReportChart';
import TotalProfitsChart from '../../../components/molecules/Charts/TotalProfitsChart';
// import { CSVLink } from 'react-csv';

export const SellerDashboard = () => {
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(1);
  const pageSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE ? parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE) : 10;
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { data: dashboardOverview } = useQuery(['seller_dashboard_overview'], getSellerDashboardOverview, {
    staleTime: Infinity
  });

  // const { data: newOrdersOverview, isFetchedAfterMount: newOrdersIsFetchedAfterMount } = useQuery(
  //   ['seller_new_orders', { orderType: 'new_order' }],
  //   getSellerOverviewOrders,
  //   { staleTime: Infinity }
  // );
  const { data: recentOrdersOverview, isFetchedAfterMount: recentOrdersIsFetchedAfterMount } = useQuery(
    ['seller_recent_orders', { orderType: 'recent_order', page: page - 1, size: pageSize }],
    getSellerOverviewOrders,
    { staleTime: Infinity }
  );

  const { refetch } = useQuery(['seller_orders_as_csv'], getSellerOrdersAsCsv, {
    enabled: false,
    onSuccess: data => {
      const csvData = data.data;
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, `${new Date().toDateString()} orders report.csv`);

      enqueueSnackbar('Report downloaded', {
        variant: 'success',
        autoHideDuration: 2000
      });
    }
  });

  const overviewData = dashboardOverview?.data || [];
  // const newOrders = newOrdersOverview?.data || [];
  const recentOrders = recentOrdersOverview?.data || [];

  const topCardData = [
    {
      title: 'Total Sales',
      total: overviewData.totalSales,
      Icon: GraphIcon,
      iconBg: '#F5FBEF'
      // percentage: '+35.5%'
    },
    {
      title: 'Total Orders',
      total: overviewData.totalOrders,
      Icon: BoxOrangeIcon,
      iconBg: '#EFF1FB'
      // percentage: '+35.5%'
    },

    {
      title: 'Orders Returned',
      total: overviewData.ordersReturned,
      Icon: BoxBlueIcon,
      // percentage: '-3.93%',
      iconBg: '#EFF1FB'
    },

    {
      title: 'Total Customers',
      total: overviewData.totalCustomer,
      // percentage: '+25.3%',
      Icon: ProfileUserIcon,
      iconBg: '#EFF1FB'
    },

    {
      title: 'Cart Abandonment',
      total: overviewData.cartAbandonment,
      // percentage: '-26.3%',
      Icon: ClipboardCloseIcon,
      iconBg: '#F5FBEF'
    }
  ];

  return (
    <DashboardCON>
      <TopContentCON>
        <div>
          <h3>Overview</h3>
          <p>
            Good morning, Seller <CloudIcon />
          </p>
        </div>
        <div>
          <Button onClick={() => refetch()}>
            Export Report as CSV
            <UploadIcon />
          </Button>
          {/* <CSVLink data={""} headers={""} filename="orders-report.csv" target="_blank" /> */}
        </div>
      </TopContentCON>

      {!md && (
        <div>
          <TopHeaderCard gap="20px" data={topCardData} />
        </div>
      )}

      {md && <TopHeaderCard data={topCardData} />}

      <Spacer height="24px" />

      <ChartsCON>
        {!md ? (
          <>
            <SalesReportChart width="50rem" />
            <TotalProfitsChart width="30rem" />
          </>
        ) : (
          <>
            <SalesReportChart height="250" />
            <TotalProfitsChart />
          </>
        )}
      </ChartsCON>

      <Spacer height="24px" />

      <DashboardTableCON>
        <DashboardTabs value={value} onChange={handleTabChange}>
          {/* <DashboardTab label="New Orders" {...a11yProps(0)} /> */}
          <DashboardTab label="Recent Orders" {...a11yProps(0)} />
        </DashboardTabs>
        <TabPanel index={0} value={value}>
          <CustomTable
            isLoading={recentOrdersIsFetchedAfterMount}
            columns={newOrderColumn}
            data={recentOrders}
            totalItems={recentOrdersOverview?.totalElements ?? 0}
            totalPages={recentOrdersOverview?.totalPages ?? 0}
            currentPage={page}
            setCurrentPage={setPage}
          />
        </TabPanel>
        {/* <TabPanel index={1} value={value}>
          <CustomTable isLoading={newOrdersIsFetchedAfterMount} columns={newOrderColumn} data={newOrders} />
        </TabPanel> */}
      </DashboardTableCON>
    </DashboardCON>
  );
};
