import React from 'react';
import { CashIcon, WalletSmallIcon } from '../../../assets/Icons';
import { WalletCard } from '../../../components/molecules';
import { SellerWalletStatsCarousel, SellerWalletStatsCon } from './styles';
import { formatCurrency } from '../../../utils/currencyFormater';
import { useQuery } from 'react-query';
import { getSellerWalletBalance } from '../../../network/services/wallet';
import { Theme, useMediaQuery } from '@mui/material';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const SellerWalletStats = () => {
  const { data: sellerWalletBalance } = useQuery('wallet_balance', getSellerWalletBalance);
  const walletBalance = sellerWalletBalance?.data ?? null;

  const md = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1162 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1162, min: 900 },
      items: 1
    },
    small_tablet: {
      breakpoint: { max: 900, min: 800 },
      items: 3
    },
    large_phone: {
      breakpoint: { max: 800, min: 600 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1
    }
  };

  return md ? (
    <SellerWalletStatsCarousel>
      <Carousel autoPlay infinite arrows={false} showDots className="carousel-con" responsive={responsive}>
        <WalletCard
          label="Wallet Balance"
          Icon={WalletSmallIcon}
          amount={formatCurrency(walletBalance?.wallet_balance ?? 0)}
        />
        <WalletCard
          label="Available points"
          showBgImage={false}
          Icon={CashIcon}
          amount={`${walletBalance?.point_balance ?? 0}`}
        />
        {/* <WalletCard label="Convert points to cash" Icon={ConversionIcon} onClick={showModal} /> */}
        {/* <WalletCard label="Logistics Balance" Icon={WalletSmallIcon} amount={formatCurrency(wallet?.balance ?? 0)} /> */}
      </Carousel>
    </SellerWalletStatsCarousel>
  ) : (
    <SellerWalletStatsCon>
      <WalletCard
        label="Wallet Balance"
        Icon={WalletSmallIcon}
        amount={formatCurrency(walletBalance?.wallet_balance ?? 0)}
      />
      <WalletCard
        label="Available points"
        showBgImage={false}
        Icon={CashIcon}
        amount={`${walletBalance?.point_balance ?? 0}`}
      />
      {/* <WalletCard label="Convert points to cash" Icon={ConversionIcon} onClick={showModal} /> */}
      {/* <WalletCard label="Logistics Balance" Icon={WalletSmallIcon} amount={formatCurrency(wallet?.balance ?? 0)} /> */}
    </SellerWalletStatsCon>
  );
};

export default SellerWalletStats;
