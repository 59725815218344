import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';

export const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  minHeight: '100%',
  minWidth: '100%',
  width: '100%',
  backgroundColor: '#fff',
  borderRadius: 'none',
  border: '0px',
  '.MuiDataGrid-columnHeaders': {
    backgroundColor: '#F0F0F0',
    fontSize: '14px',
    color: '#483D3D',
    fontFamily: 'Outfit',
    fontWeight: '400',
    padding: '12px 16px',
    height: '60px',
    lineHieght: '24px'
  },

  '.MuiDataGrid-cell': {
    backgroundColor: '#fff',
    fontSize: '14px',
    color: '#483D3D',
    padding: '12px 30px',
    fontFamily: 'Outfit',
    fontWeight: '400',
    lineHieght: '24px'
  },

  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
    outline: "none !important",
  },
  "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
    {
      outline: "none !important",
    },

  '.MuiTablePagination-root': {
      display: 'none'
  }
  // [theme.breakpoints.down('lg')]: {
  //   maxWidth: '100%',
  //   '& .MuiTabs-indicator': {
  //     display: 'unset'
  //   }
  // }
}));

export const TableGeneralCon = styled('div')`
  width: 100%;
  height: 700px;
  .pagination-con {
    padding: 20px 30px;
  }
`;
