import { styled } from '@mui/material';
export const ProductQtyCounterCon = styled('div')`
  display: flex;
  align-items: center;

  input {
    width: 3rem;
    text-align: center;
    background-color: #fff;
    border: none;
    font-weight: 300;
    font-size: 0.875rem;
    &:disabled {
      color: #666;
    }
  }
  svg {
    cursor: pointer;
  }
`;
