import styled from '@emotion/styled';
import { breakpoints } from '../../../theme/theme';

export const ChartCON = styled('div')<{ width?: string }>`
  display: flex;
  width: ${props => props.width || '100%'};
  margin: 28px 0px 0px 0px;
  background: white;
  border-radius: 12px;
  padding: 24px 24px;
  align-items: center;
  flex-direction: column;

  
  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    margin-bottom: 24px;
    
    @media (max-width: ${breakpoints.md}px) {
      flex-direction: column;
    }
  }

  .metadata {
    .legend {
      display: flex;
      align-items: center;
      justify-content: center;

      > *:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  .export {
    display: flex;
    align-items: center;
    justify-content: center;
    > *:not(:last-child) {
      margin-right: 1rem;
    }
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;

    @media (max-width: ${breakpoints.md}px) {
      font-size: 1.4rem;
      font-weight: 500;
    }
  }

  h4 {
    font-size: 1.2rem;
    font-weight: 500;

    @media (max-width: ${breakpoints.md}px) {
      font-size: 1rem;
      font-weight: 400;
    }
  }
`;
