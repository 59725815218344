import { styled } from '@mui/material';
import { COLORS } from '../../../theme/color';
type LogoConProps = {
  $noFill?: boolean;
};

export const LogoCon = styled('div')<LogoConProps>`
  cursor: pointer;
  width: 9.6rem;
  height: 2.8rem;

  .logo {
    width: 100%;
    path {
      fill: ${props => (props.$noFill ? COLORS.White : COLORS.MainBlue)};
    }
  }
`;
