import React from 'react';
import { InputCon } from '../../../pages/Product/AddProduct/style';
import { Button, InputField, Spacer } from '../../atoms';
import { BtnExtraStyles, UploadCon, InputExtraStyles } from './style';

export const APIUpload = () => {
  return (
    <UploadCon>
      <Spacer height="31px" />
      <InputCon>
        <span>Odoo Base Url</span>
        <InputField extraStyles={InputExtraStyles} placeholder='Enter Odo Base URL' name="url" />
      </InputCon>

      <InputCon>
        <span>Odoo dbName</span>
        <InputField extraStyles={InputExtraStyles} placeholder='Enter Odoo dbName' name="url" />
      </InputCon>

      <InputCon>
        <span>Odoo Username</span>
        <InputField extraStyles={InputExtraStyles} placeholder='Enter Odoo Username' name="url" />
      </InputCon>

      <InputCon>
        <span>Odoo Password</span>
        <InputField extraStyles={InputExtraStyles} placeholder='Enter Odoo Password' name="url" />
      </InputCon>

      <Spacer height="31px" />

      <Button extraStyles={BtnExtraStyles}>Upload</Button>
    </UploadCon>
  );
};
