import { styled } from "@mui/material";
import { COLORS } from "../../../theme/color";
import { breakpoints } from "../../../theme/theme";

export const OrderButton = styled('button')<{ outlined?: boolean; color?: string; bgColor?: string }>`
  width: 180px;
  height: 40px;
  border-radius: 4px;

  color: ${props => (!props.outlined ? '#fff' : props.color)};

  background-color: ${props => (props.outlined ? COLORS.Transparent : props.bgColor)};

  outline: ${props => (props.outlined ? `1px solid` : 'none')};

  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  &:hover {
    opacity: 0.85;
    transform: scale(1.005);
  }
  &:active {
    opacity: 0.9;
    transform: scale(0.995);
  }
  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
    padding: 0 20px;
  }
`;