import { CircularProgress, styled } from '@mui/material';
import { COLORS } from '../../../theme/color';
import { breakpoints } from '../../../theme/theme';

export const AddressCON = styled('div')`
  width: 100%;
  min-height: 156px;
  border: 1px solid #e9e5e5;
  border-radius: 8px;
  padding: 20px 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${COLORS.SecBlack};

  .name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
    color: ${COLORS.MainBlack};
  }

  .address {
    font-weight: 700;
    margin-bottom: 8px;
  }

  .default {
    width: 82px;
    background: ${COLORS.SecBlue};
    border-radius: 4px;
    font-size: 12px;
    line-height: 20px;
    color: ${COLORS.MainBlue};
    text-align: center;
    margin-left: auto;
    margin-top: 8px;
    padding: 4px 0;
  }
  @media (max-width: ${breakpoints.sm}px) {
    padding: 20px;
  }
`;

export const DropCON = styled('div')`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  div {
    padding: 16px 30px 16px 20px;
    display: flex;
    align-items: center;
    flex: 1;
    border-bottom: 1px solid #e6e6e6;
    cursor: pointer;
    &:last-of-type {
      border-bottom: none;
    }
    &:hover {
      color: ${COLORS.MainBlue};
    }
  }
`;

export const AddressModalCon = styled('form')`
  h3 {
    font-weight: 700;
    font-size: 24px;
    margin: 0 0 11px 0;
  }
  label {
    font-weight: 700;
    font-size: 14px;
    display: inline-block;
    margin: 24px 0 8px 0;
  }
  .name-div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
  input::placeholder {
    font-size: 14px;
  }
  button {
    margin: 26px 0 8px 0;
  }
`;

export const AddressCon = styled('div')`
  width: 300px;
  background: #fff;
  box-shadow: 0px 2px 16px rgba(189, 189, 189, 0.25);
  border-radius: 6px;
  padding: 16px 16px 16px;
  .heading {
    font-weight: 600;
    font-size: 15px;
    line-height: 27px;
    color: #180101;
  }

  input {
    height: 40px;
    border-radius: 4px;
    border: 1px solid #e9e5e5;
    padding: 0 10px;
    font-size: 14px;
  }
`;

export const DropListCON = styled('div')`
  width: 100%;
  margin-top: 16px;
  font-family: 'Euclid Circular A', 'Outfit';

  .list-con {
    max-height: 130px;
    overflow-y: scroll;
    border: 1px solid #e9e5e5;
    border-radius: 4px;
  }

  .list {
    width: 100%;
    padding: 12px 16px;
    &:hover {
      background-color: #efefef;
    }
    cursor: pointer;
    transition: background-color 150ms ease;
  }
  .list[data-current='true'] {
    background-color: #efefef;
  }
  .desc {
    font-size: 10px;
    line-height: 16px;
    color: ${COLORS.SecBlack};
  }
  .name {
    font-size: 13px;
    line-height: 24px;
    font-weight: 500;
  }
  .loading {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: ${COLORS.SecBlack};
  }
`;

export const Spinner = styled(CircularProgress)({
  color: COLORS.MainGray
});
