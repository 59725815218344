import { MenuItem, SelectChangeEvent } from '@mui/material';
import { Button, InputField, Spacer } from '../../../../components/atoms';
import { Modal } from '../../../../components/molecules';
import { AdduserCon, CustomSelect, InputCon, extraStylesInput } from '../AddUser/style';
import { useState } from 'react';
import { COMMONTYPES } from '../../../../TYPES/event.types';
import { DropdownIcon2 } from '../../../../assets/Icons';
import { getRoles, updateUserRole } from '../../../../network/services/users';
import { useQuery, useQueryClient } from 'react-query';
import { useMutationHook } from '../../../../network/mutations/useMutationHook';

type AddUserprops = {
  role: any;
  onModalClose: () => void;
};

const INITIAL_STATE = {
  email: '',
  sellerRoleId: 1
};

export const ChangeUserRole = ({ role, onModalClose }: AddUserprops) => {
  const [formData, setFormData] = useState({ ...INITIAL_STATE, email: role.email ?? '' });
  const { data } = useQuery('user_roles', getRoles);
  const roles = data || [];

  const queryClient = useQueryClient();
  const onSelectChange = (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target;
    setFormData(formData => ({ ...formData, [name]: value }));
  };
  const onInputChange = (e: COMMONTYPES['inputChange']) => {
    const { name, value } = e.target;
    setFormData(formData => ({ ...formData, [name]: value }));
  };
  const selectClick = (e: COMMONTYPES['divClick']) => e.stopPropagation();

  const { mutateAsync, isLoading } = useMutationHook(updateUserRole, 'UPDATE_USER_ROLE');

  const _createUser = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    try {
      const { data } = await mutateAsync({ previousRoleId: role.id, sellerRoleId: formData.sellerRoleId });
      if (data.status === 'success') {
        queryClient.invalidateQueries('seller_users');
        onModalClose();
      }
    } catch (error: any) {}
  };

  const disabled = !formData.email;
  return (
    <Modal title="Update User Role" maxWidth="500px" onModalClose={onModalClose}>
      <AdduserCon>
        <InputCon>
          <span>Email</span>
          <InputField
            extraStyles={extraStylesInput}
            defaultValue={role.email}
            disabled
            onChange={onInputChange}
            name="email"
          />
        </InputCon>
        <Spacer height="22px" />
        <InputCon>
          <span>Role </span>
          <CustomSelect
            IconComponent={DropdownIcon2}
            name="sellerRoleId"
            value={formData.sellerRoleId}
            onChange={onSelectChange}
            onClick={selectClick}>
            {roles.map((item: any) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </CustomSelect>
        </InputCon>

        <Spacer height="52px" />

        <Button onClick={_createUser} isLoading={isLoading} disabled={disabled}>
          Update{' '}
        </Button>
      </AdduserCon>
    </Modal>
  );
};
