import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { NoteIcon } from '../../../assets/Icons';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { convertPointsToNaira, pointNairaEquivalent } from '../../../network/services/wallet';
import { Button, InputField } from '../../atoms';
import { Modal } from '../Modal/Modal';
import { SideModalContentStyle, SideModalStyle } from '../Modal/style';
import { ConvertPointModalCon } from './styles';

const initial = {
  x: '100%',
  height: 'auto'
};
const animate = {
  x: '0'
};

type PointModalProp = {
  setIsPoint: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ConvertPointModal = ({ setIsPoint }: PointModalProp) => {
  const setModalClose = () => {
    setIsPoint(false);
  };

  const queryClient = useQueryClient();
  const [pointsToConvert, setPointsToConvert] = useState<number>(0);

  const { mutateAsync, isLoading: isLoadingConvertPointsToNaira } = useMutationHook(convertPointsToNaira);

  const handleConvert = async () => {
    try {
      const response = await mutateAsync(pointsToConvert);
      if ((response as any).status === 'success') {
        setPointsToConvert(0);
        setModalClose();
      }
      queryClient.invalidateQueries('wallet_balance');
    } catch (error: any) {}
  };

  const { data, isLoading: isLoadingPointEquivalent } = useQuery('pointNairaEquivalent', pointNairaEquivalent);
  const disabled = pointsToConvert === 0 || isLoadingConvertPointsToNaira;
  return (
    <Modal
      onModalClose={setModalClose}
      extraModalStyle={SideModalStyle}
      extraModalContentStyle={SideModalContentStyle}
      initial={initial}
      animate={animate}>
      <ConvertPointModalCon>
        <div className="first">
          <h3>Convert points</h3>
          <div className="info">
            <NoteIcon />
            <div>
              <p>Exchange value</p>
              {isLoadingPointEquivalent ? <p>Loading...</p> : <p>1 MADINA POINT IS EQUIVALENT TO ₦{data}</p>}
            </div>
          </div>
          <InputField
            label="Amount of points to convert"
            type="number"
            defaultValue="0"
            name="points"
            onChange={e => setPointsToConvert(Number(e.target.value))}
          />
        </div>
        <Button disabled={disabled} isLoading={isLoadingConvertPointsToNaira} onClick={handleConvert}>
          Convert
        </Button>
      </ConvertPointModalCon>
    </Modal>
  );
};
