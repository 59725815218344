import React from 'react';
import { ChartLegendItemCON, LegendIndicator } from './style';

type ChartLegendItemProps = {
    labelName: string;
    colorHex: string;
};

const ChartLegendItem = ({labelName, colorHex}: ChartLegendItemProps) => {
  return (
    <ChartLegendItemCON>
        <LegendIndicator colorHex={colorHex} />
      <div className="label">{labelName}</div>
    </ChartLegendItemCON>
  );
};

export default ChartLegendItem;