import { styled } from '@mui/material';

export const UploadCon = styled('div')(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.down('lg')]: {}
}));

export const BtnExtraStyles = {
  borderRadius: '12px',
}

export const InputExtraStyles = {
  alignItems: 'center',
  padding: '12px',
  gap: '10px',
  width: '100%',
  borderRadius: '16px',
  border: '1px solid #E6E6E6',

  height: '56px',
  opacity: '0.8'
};