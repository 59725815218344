import { styled } from '@mui/material';
// import { breakpoints } from '../../../theme/theme';

export const TableRowProductCardCon = styled('p')`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50px;
  }
`;
