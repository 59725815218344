import { styled } from '@mui/material';
import { COLORS } from '../../theme/color';
import { breakpoints } from '../../theme/theme';

export const HeaderCON = styled('div')`
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 32px;
`;

export const EmptyCON = styled('div')`
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -32px;
`;

export const BasicBox = styled('div')`
  max-width: 854px;
  background: ${COLORS.White};
  padding: 2rem 2rem 2.5rem;
  display: flex;
  flex-direction: column;

  .header {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    padding-bottom: 24px;
    margin-bottom: 40px;
    border-bottom: 1px solid #ededed;
  }
  @media (max-width: ${breakpoints.sm}px) {
    padding: 5vw 5vw;
  }
`;
