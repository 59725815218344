import { styled } from '@mui/material';
import { COLORS } from '../../../theme/color';

export const paperProps = {
  elevation: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  sx: {
    overflow: 'visible'
  },
  style: {
    width: 'max-content',
    maxWidth: '420px',
    backgroundColor: COLORS.White,
    filter: 'drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.05))',
    mt: 0,
    zIndex: 10,

    maxHeight: '500px',
    height: 'max-content',
    overflow: 'scroll'
  }
};

export const DropDownCon = {
  width: { xs: '88%', lg: '100%' }
};

export const DropCon = styled('div')`
  button {
    width: max-content;
    height: max-content;
    margin: 0;
    border: none;
    cursor: pointer;
    background-color: transparent;
  }

  .content {
    padding: 1rem;
    background-color: red;
  }
`;
