import { enqueueSnackbar } from 'notistack';
import { ChangeEvent, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { HidePassword, ShowPassword } from '../../../assets/Icons';
import { Button, Container, Spacer } from '../../../components/atoms';
import { InputField } from '../../../components/atoms/InputField/InputField';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { confirmPasswordReset } from '../../../network/services/auth';
import { validators } from '../../../utils/validator';
import { AuthContainer, InputCon, TogglePassword, extraStylesInput } from '../styles';

const INITIALSTATE = {
  newPassword: '',
  confirm_password: ''
};

const ResetNewPassword = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formData, setFormData] = useState(INITIALSTATE);

  const navigate = useNavigate();
  const { otp } = useParams();

  const [formError, setFormError] = useState(INITIALSTATE);

  const onUpdateFormData = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    const error = validators[name](value);
    setFormData(formData => ({ ...formData, [name]: value }));
    setFormError({
      ...formError,
      [name]: error
    });
  };

  const [showPassword, setShowPassword] = useState<'text' | 'password'>('password');

  const onTogglePassword = () => {
    if (showPassword === 'text') return setShowPassword('password');
    setShowPassword('text');
  };

  const { mutateAsync, isLoading } = useMutationHook(confirmPasswordReset, 'CONFIRM_PASSWORD_RESET');

  const _confirmPasswordReset = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (!otp) {
      enqueueSnackbar('Invalid or Missing OTP', { variant: 'error' });
      return;
    }

    try {
      const { data } = await mutateAsync({ otp: otp, newPassword: formData.newPassword });

      if (data.status === 'success') {
        navigate('/');
      }
    } catch (error: any) {
      navigate(-1);
    }
  };

  const disabled =
    !validators.email(formData.newPassword) ||
    !validators.email(formData.confirm_password) ||
    formData.newPassword !== formData.confirm_password ||
    formData.newPassword.length < 8 ||
    formData.confirm_password.length < 8;

  return (
    <AuthContainer>
      <div>
        <Spacer height="4rem" />

        <h1>Reset Password</h1>
        <p className="option">Please create a secure password different from previously used password</p>

        <Container>
          <form className="sign-up">
            <Spacer height="1.5rem" />
            <InputCon>
              <span>New Password</span>
              <InputField
                type={showPassword}
                name="newPassword"
                onChange={onUpdateFormData}
                placeholder="enter your password"
                extraStyles={extraStylesInput}
                // required={true}
                error={formError.newPassword}
                action={
                  <TogglePassword onClick={onTogglePassword}>
                    {showPassword !== 'text' ? <ShowPassword /> : <HidePassword />}
                  </TogglePassword>
                }
                autoComplete="new-passowrd"
              />
            </InputCon>
            <InputCon>
              <span>Confirm New Password</span>
              <InputField
                type={showPassword}
                name="confirm_password"
                onChange={onUpdateFormData}
                placeholder="confirm your password"
                extraStyles={extraStylesInput}
                // required={true}
                error={formError.confirm_password}
                action={
                  <TogglePassword onClick={onTogglePassword}>
                    {showPassword !== 'text' ? <ShowPassword /> : <HidePassword />}
                  </TogglePassword>
                }
                autoComplete="confirm-new-passowrd"
              />
            </InputCon>

            <Spacer height="2rem" />

            <div className="buttonContainer">
              <Button isLoading={isLoading} disabled={disabled} type="submit" onClick={_confirmPasswordReset}>
                Confirm
              </Button>
            </div>
          </form>
        </Container>
      </div>
    </AuthContainer>
  );
};

export default ResetNewPassword;
