import { useMemo } from 'react';
import { LEFT_ICON, RIGHT_ICON } from '../../../assets/Icons';

type RangeType = {
  label: number;
};

type PaginationItem = {
  label?: number;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  enabled?: boolean;
  type?: 'left' | 'right';
};

export type typeUsepagination = {
  pages: number;
  currentPage: number;
};

const range = (start: number, end: number): RangeType[] =>
  Array.from({ length: end - start + 1 }, (_, i) => ({ label: i + start }));

export const usePagination = ({ pages, currentPage }: typeUsepagination) => {
  const paginationItems: PaginationItem[] = useMemo(() => {
    let siblingCount = 1;

    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= pages) {
      return range(1, pages);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, pages);

    const shouldShowLeftArrow = leftSiblingIndex > 2;
    const shouldShowRightArrow = rightSiblingIndex < pages - 2;

    let rangeArray: RangeType[] = [];

    if (!shouldShowLeftArrow && shouldShowRightArrow) {
      const leftItemCount = 3 + 2 * siblingCount;
      rangeArray = range(1, leftItemCount);
    }

    if (shouldShowLeftArrow && !shouldShowRightArrow) {
      const rightItemCount = 2 + 2 * siblingCount;
      rangeArray = range(pages - rightItemCount, pages);
    }

    if (shouldShowLeftArrow && shouldShowRightArrow) {
      rangeArray = range(leftSiblingIndex - 1, rightSiblingIndex + 1);
    }

    return [
      { icon: LEFT_ICON, enabled: shouldShowLeftArrow, type: 'left' },
      ...rangeArray,
      { icon: RIGHT_ICON, enabled: shouldShowRightArrow, type: 'right' }
    ];
  }, [pages, currentPage]);

  return { paginationItems };
};
