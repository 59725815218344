import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { styled } from '@mui/material';
import {  useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';

type customBreadcrumb = {
  firstLabel: string;
  secondLabel: string;
};

const BreadcrumbLinkCON = styled('div')`
cursor: pointer;
  .firstLabel {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .secondLabel {
    font-family: Outfit;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const CustomBreadcrumb = ({ firstLabel, secondLabel }: customBreadcrumb) => {
  const navigate = useNavigate()
  const breadcrumbs = [
    <Link underline="hover" className="firstLabel" key="1" color="inherit" onClick={() => navigate(-1)}>
      {firstLabel}
    </Link>,
    <Link underline="hover" key="2" className="secondLabel" color="inherit" >
      {secondLabel}
    </Link>
  ];

  return (
    <Stack spacing={2}>
      <BreadcrumbLinkCON>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </BreadcrumbLinkCON>
    </Stack>
  );
};
