import React from 'react';
import { Box, Drawer, List, ListItemText, Divider } from '@mui/material';
// import { Box, Drawer, List, ListItemButton, ListItemText, Divider } from '@mui/material';
import { LogoutIcon } from '../../../assets/Icons';
import { CustomListItemButton, DrawerHeader, ImgBox, headTextStyle, textStyle } from './styles';
import { FILTER_KEYS } from '../../../store/keys';
import { Logo, Spacer } from '../../atoms';
import { CancelIcon } from '../../../assets/Icons';
import { sellerNavs } from '../../../navigation/SellerRoutes/navs';

import { useLocation, useNavigate } from 'react-router-dom';
import { logout } from '../../../network/services/auth';
import { apiResponse } from '../../../network/config/message.constants';
import { useRecoilState } from 'recoil';
import { userIsLoggedIn, userRoleAtom } from '../../../store/atoms/userAtom';

type SideBarProp = {
  side: boolean;
  setSide: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SideBar = ({ side, setSide }: SideBarProp) => {
  const navigate = useNavigate();
  const [isLogged, setUserStatus] = useRecoilState(userIsLoggedIn);
  const [userRole, setUserRole] = useRecoilState(userRoleAtom);
  const _onRouting = (to: string) => {
    setSide(false);
    navigate(to);
  };

  const _logout = async () => {
    try {
      const data = await logout();
      apiResponse(data, 'success', 'LOGOUT');
    } catch (error) {
      console.log(error);
    }
    setUserRole('');
    setUserStatus(false);

    sessionStorage.clear();
    userRole === 'admin' && navigate('/admin');
    userRole === 'seller' && navigate('/');
  };

  const { pathname } = useLocation();

  const toggleDrawer = (open: any) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setSide(!side);
  };

  const { ADMIN_ROLE, SELLER_ROLE } = FILTER_KEYS;
  return (
    <Drawer
      anchor="left"
      open={side}
      onClose={toggleDrawer(false)}
      sx={{ '.MuiDrawer-paper': { backgroundColor: '#fff' } }}>
      <Spacer height={20} />

      <DrawerHeader>
        <Logo />
        <Spacer width={50} />
        <CancelIcon onClick={() => setSide(false)} />
      </DrawerHeader>
      <Spacer height={30} />

      <Divider />
      <List>
        <ListItemText sx={headTextStyle} />
        {sellerNavs.map(({ label, Icon, to }) => (
          <CustomListItemButton
            isActive={pathname.includes(to) ? true : false}
            key={label}
            onClick={() => _onRouting(to)}>
            <Box sx={ImgBox}>
              <Icon />
            </Box>
            <ListItemText primary={label} sx={textStyle} />
            <Spacer height="50px" />
          </CustomListItemButton>
        ))}
        <CustomListItemButton isActive={false} onClick={_logout}>
          <Box sx={ImgBox}>
            <LogoutIcon />
          </Box>
          <ListItemText primary="Log Out" sx={textStyle} />
        </CustomListItemButton>
      </List>
    </Drawer>
  );
};
