import { SupportAction } from '../../actions';
import { GreenDot } from '../../assets/Icons';
// import { Button } from '../../components/atoms';
import { ActionCON, CustomerStatusCON, extraButtonStyles } from './style';

export const columns = [
  {
    id: 1,
    field: 'email',
    headerName: 'User Email',
    flex: 1,
    minWidth: 150
  },

  {
    id: 2,
    field: 'complaint',
    headerName: 'Complaint',
    flex: 1,
    minWidth: 200
  },

  {
    id: 8,
    field: 'status',
    headerName: 'Status',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => (
      <ActionCON>
        <CustomerStatusCON status={row.status}>
          <GreenDot />
          <p>{row.status}</p>
        </CustomerStatusCON>
      </ActionCON>
    )
  },

  {
    id: 3,
    field: 'date',
    headerName: ' Date',
    flex: 1,
    minWidth: 100
  },

  {
    id: 8,
    field: 'action',
    headerName: 'Action',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => (
      <ActionCON>
        <SupportAction extraButtonStyles={extraButtonStyles} />
      </ActionCON>
    )
  }
];
export const products = [
  {
    id: 1,
    complaint: 'I want to request for..',
    email: 'eleanorpena@gmail.com',
    date: '7/03/22, 08:00pm',
    status: 'Pending',
    flex: 1,
    minWidth: 330
  },

  {
    id: 2,
    complaint: 'I want to request for..',
    email: 'eleanorpena@gmail.com',
    date: '7/03/22, 08:00pm',
    status: 'Pending',
    flex: 1,
    minWidth: 330
  },

  {
    id: 3,
    complaint: 'I want to request for..',
    email: 'eleanorpena@gmail.com',
    date: '7/03/22, 08:00pm',
    status: 'Closed',
    flex: 1,
    minWidth: 330
  },

  {
    id: 4,
    complaint: 'I want to request for..',
    email: 'eleanorpena@gmail.com',
    date: '7/03/22, 08:00pm',
    status: 'Open',
    flex: 1,
    minWidth: 330
  },

  {
    id: 5,
    complaint: 'I want to request for..',
    email: 'eleanorpena@gmail.com',
    date: '7/03/22, 08:00pm',
    status: 'Closed',
    flex: 1,
    minWidth: 330
  }
];
