import { ChangeEvent, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Spacer } from '../../../components/atoms';
import { InputField } from '../../../components/atoms/InputField/InputField';
import { FORGOTPASSWORD, OTP } from '../../../navigation/routes';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { forgotPassword } from '../../../network/services/auth';
import { validators } from '../../../utils/validator';
import { AuthContainer, InputCon, extraStylesInput } from '../styles';

const INITIALSTATE = {
  email: '',
  password: ''
};

const ForgotPassword = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formData, setFormData] = useState(INITIALSTATE);

  const navigate = useNavigate();

  const [formError, setFormError] = useState(INITIALSTATE);

  const onUpdateFormData = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    const error = validators[name](value);
    setFormData(formData => ({ ...formData, [name]: value }));
    setFormError({
      ...formError,
      [name]: error
    });
  };

  const { mutateAsync, isLoading } = useMutationHook(forgotPassword, 'FORGOT_PASSWORD');

  const _forgotPassword = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    try {
      const { data } = await mutateAsync(formData);

      if (data.status === 'success') {
        navigate(`otp/${formData.email}`);
      }
    } catch (error: any) {}
  };

  return (
    <AuthContainer>
      <div>
        <Spacer height="4rem" />

        <h1>Forgot Password?</h1>
        <p className="option">An OTP verification code will be sent to your email</p>

        <Container>
          <form className="sign-up">
            <Spacer height="1.5rem" />
            <InputCon>
              <span>Email Address</span>
              <InputField
                type="email"
                name="email"
                onChange={onUpdateFormData}
                placeholder="example@mail.com"
                extraStyles={extraStylesInput}
                required={true}
                error={formError.email}
                autoComplete="username"
              />
            </InputCon>

            <Spacer height="2rem" />

            <div className="buttonContainer">
              <Button isLoading={isLoading} type="submit" onClick={_forgotPassword}>
                Reset
              </Button>
            </div>
          </form>
        </Container>
      </div>
    </AuthContainer>
  );
};

export default ForgotPassword;
