import { useEffect, useRef, useState } from 'react';
import { Button } from '../Button/Button';
import { Divider } from '../Divider/Divider';
import { InputField } from '../InputField/InputField';
import {
  FormCON,
  ImageCON,
  RemoveBtnExtraStyles,
  SettingsInfoCON,
  UploadBtnExtraStyles,
  InputCON,
  InputExtraStyles,
  DeleteAccountCON,
  SubmitButtonCON,
  DeleteButtonExtraStyles
} from './style';
// import { getCustomer } from '../../../network/services/profile';
import { Modal } from '../../molecules';
import UploadPicture from './UploadPicture';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userAtom, userIsLoggedIn, userRoleAtom } from '../../../store/atoms/userAtom';
import SellerAvatar from '../../../assets/images/seller-avatar.jpeg';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { deleteAccount } from '../../../network/services/profile';
import { TogglePassword } from '../../../pages/Auth/styles';
import { HidePassword, ShowPassword } from '../../../assets/Icons';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../network/services/auth';

export const SettingsInformation = () => {
  const [openUploadSellerLogoModal, setOpenUploadSellerLogoModal] = useState<boolean>(false);
  const [openDeleteAccountModal, setOpenDeleteAccountModal] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const user = useRecoilValue(userAtom);
  // const lastNameRef = useRef<HTMLInputElement>(null);
  const businessNameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [isLogged, setUserStatus] = useRecoilState(userIsLoggedIn);
  const [userRole, setUserRole] = useRecoilState(userRoleAtom);

  useEffect(() => {
    const setSellerDetails = () => {
      businessNameRef.current!.value = user.business_name || '';
      emailRef.current!.value = user.email || '';
      phoneNumberRef.current!.value = user.phoneNumber || '';
    };
    setSellerDetails();
  }, [user]);

  const onConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  const [showPassword, setShowPassword] = useState<'text' | 'password'>('password');

  const onTogglePassword = () => {
    if (showPassword === 'text') return setShowPassword('password');
    setShowPassword('text');
  };

  const { mutateAsync, isLoading } = useMutationHook(deleteAccount, 'DELETE_ACCOUNT');
  const { mutateAsync: mutateAsyncLogOut } = useMutationHook(logout, 'LOGOUT');

  const onDeleteAccountSuccess = async () => {
    try {
      await mutateAsyncLogOut(undefined);
    } catch (error) {
      console.log(error);
    }
    setUserRole('');
    setUserStatus(false);

    sessionStorage.clear();
    userRole === 'admin' && navigate('/admin');
    userRole === 'seller' && navigate('/');
  };

  const onDeleteAccount = async () => {
    try {
      const deleteAccount: any = await mutateAsync({ password: confirmPassword });
      console.log('deleteAccount', deleteAccount);
      if (deleteAccount.status === 'success') {
        setOpenDeleteAccountModal(false);
        onDeleteAccountSuccess();
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const disabled = !confirmPassword;

  return (
    <SettingsInfoCON>
      <ImageCON>
        <div>
          <img src={user.profilePicture ?? SellerAvatar} alt="" />
          <Button
            onClick={() => setOpenUploadSellerLogoModal(!openUploadSellerLogoModal)}
            extraStyles={UploadBtnExtraStyles}>
            Upload
          </Button>
          <Button extraStyles={RemoveBtnExtraStyles}>Remove</Button>
        </div>
      </ImageCON>
      <FormCON>
        <InputCON>
          <span>Display Name</span>
          <InputField inputRef={businessNameRef} disabled extraStyles={InputExtraStyles} name="name" />
        </InputCON>
        <InputCON>
          <span>Email Address</span>
          <InputField inputRef={emailRef} disabled extraStyles={InputExtraStyles} name="emailAddress" />
        </InputCON>

        <InputCON>
          <span>Mobile</span>
          <InputField inputRef={phoneNumberRef} disabled extraStyles={InputExtraStyles} name="phoneNumber" />
        </InputCON>
      </FormCON>

      <DeleteAccountCON>
        <Button extraStyles={DeleteButtonExtraStyles} onClick={() => setOpenDeleteAccountModal(true)}>
          Delete Account
        </Button>
        <p>By deleting your account, you loose all data</p>
      </DeleteAccountCON>
      <Divider />
      {/* <SubmitButtonCON>
        <Button>Update Changes</Button>
      </SubmitButtonCON> */}
      {openUploadSellerLogoModal && (
        <Modal title="Upload Image" maxWidth="44rem" onModalClose={() => setOpenUploadSellerLogoModal(false)}>
          <UploadPicture setOpenUploadSellerLogoModal={setOpenUploadSellerLogoModal} />
        </Modal>
      )}
      {openDeleteAccountModal && (
        <Modal title="Delete Account" maxWidth="44rem" onModalClose={() => setOpenDeleteAccountModal(false)}>
          <div>
            <p>Enter your password to confirm your account deletion.</p>
            <InputCON>
              <span>Password</span>
              <InputField
                type={showPassword}
                name="confirmPassword"
                onChange={onConfirmPasswordChange}
                placeholder="enter your password"
                extraStyles={InputExtraStyles}
                required={true}
                action={
                  <TogglePassword onClick={onTogglePassword}>
                    {showPassword !== 'text' ? <ShowPassword /> : <HidePassword />}
                  </TogglePassword>
                }
                autoComplete="new-passowrd"
              />
            </InputCON>
            <SubmitButtonCON>
              <Button disabled={disabled || isLoading} isLoading={isLoading} onClick={onDeleteAccount}>
                Delete Account
              </Button>
            </SubmitButtonCON>
          </div>
        </Modal>
      )}
    </SettingsInfoCON>
  );
};
