import { styled } from '@mui/material';
import { breakpoints } from '../../../../theme/theme';

export const UploadPictureCON = styled('div')`
  width: 40rem;
  min-height: 20rem;
  background-color: #ffffff;
  border-radius: 1rem;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > *:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  h3 {
    font-size: 1.6rem;
    font-weight: 500;
    color: #000000;
    margin-bottom: 1rem;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 25rem;
  }
`;

export const ImageUploadCON = styled('div')`
  padding: 2rem 5rem;
  width: inherit;
  border-radius: 10px;
  border: dashed 2px #000000; /*optional*/

  /* the initial value of border-style is "none", so it must be set to a different value for the border-image to show*/
  border-style: dashed;

  /* Individual border image properties */
  border-image-source: url('https://i.stack.imgur.com/LKclP.png');
  border-image-slice: 2;
  border-image-repeat: round;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  .image-ref {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
  }
`;

export const ImageDisplayCON = styled('div')`
  img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
  }
`;
