import { styled } from '@mui/material';

export const FAQsCON = styled('div')`
  h3 {
    color: #000;
    font-family: Outfit;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding-bottom: 20px;
  }
`;

export const FAQsContentCON = styled('div')`
  padding-top: 47px;
  display: flex;
  gap: 30px;
  flex-direction: column;

  div {
    button {
      height: 60px;
      border-radius: 8px;
    }
    p {
      font-size: 18px;
    }
  }

  p {
    color: rgba(0, 0, 0, 0.7);
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
  }
`;

export const AccordionContent = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 40px;
  p {
    color: #000;
    font-size: 23px;
    font-weight: 300;
    line-height: 39px;
  }
  .buttons {
    display: flex;
    gap: 98px;
    padding-bottom: 20px;
  }
`;

export const EditBtnExtraStyles = {
  width: '113px',
  height: '50px',
  borderRadius: '8px'
};

export const DeleteBtnExtraStyles = {
  width: '113px',
  height: '50px',
  borderRadius: '8px',
  background: '#D90429'
};
