import { useLocation } from 'react-router-dom';
import { NavLinkCon } from './style';

type LinkProps = {
  to: string;
  label: string;
  img?: any;
  Icon?: any;

  minimize?: boolean;
};

export type navLinkProps = Omit<LinkProps, 'minimize'>;

export const NavLinks = ({ to, label, img, Icon,  }: LinkProps) => {
  const {pathname} = useLocation()

  return (
    <NavLinkCon
      to={to}
      $isActive={pathname.includes(to) ? true : false}
      // style={({ isActive }) => (isActive ? true : undefined)}
    >
      {img && (
        <div className="navIcon">
          <img alt="icon" src={img} />
        </div>
      )}
      {Icon && <Icon />}
      {label}
    </NavLinkCon>
  );
};
