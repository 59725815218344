import { Select, styled } from '@mui/material';

export const CustomSelect = styled(Select)({
  width: '100%',
  minHeight: '56px',
  alignItems: 'center',
  padding: '12px',
  gap: '10px',
  borderRadius: '4px',
  background: 'transparent',
  border: '1px solid #E6E6E6',
  opacity: '0.8',
  '& .MuiInputBase-input': {
    borderRadius: 4,
    backgroundColor: '#fff',
    // border: '1px solid #dbdce9',
    fontFamily: 'Outfit'
  },
  '.MuiInputBase-input': {
    background: 'transparent'
  }
});
