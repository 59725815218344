export const HOME = '/';
export const ADMIN = '/admin/';
export const SELLER = '/seller'
export const SELLER_WALLET = '/wallet'
export const FORGOTPASSWORD = '/forgot-password';
export const RESET_NEW_PASSWORD = '/reset-new-password/:otp';
export const DASHBOARD = '/dashboard';
export const PRODUCTS = '/products';
export const ADD_PRODUCT = '/products/add';
export const PRODUCT_DETAILS = '/products/details';
export const USERS = '/users';
export const ORDERS = '/orders';
export const LOGISTICS = '/logistics';
export const ADD_LOGISTICS = '/logistics';
export const ROLES = '/roles';
export const CATEGORIES = '/categories';
export const DELIVERIES = '/deliveries';
export const LOANS = '/loans';
export const LOAN_SETTINGS = '/loans/settings';
export const FUFILLMENT = '/fulfilments';
export const CUSTOMERSUPPORT = '/customer-support';
export const SETTINGS = '/settings';
export const LOGIN = '/login';
export const SIGN_UP = '/sign-up';
export const OTP = `/otp/:email`;

