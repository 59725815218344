export const otpExtraStylesInput = {
    padding: "0.5rem 1rem",
    marginLeft: '0.25rem',
    marginRight: '0.25rem',
    borderRadius: '0.25rem',
    borderWidth: '1px',
    borderColor: '#D1D5DB',
    outlineStyle: 'none',
    width: '3.2rem',
    height: '3.2rem',
    fontSize: '1.2rem',
    lineHeight: '2.25rem',
    color: '#000000',
    backgroundColor: '#F3F4F6'
  
    // @media (min-width: "768px") {
    //     marginLeft: "0.5rem",
    //   marginRight: "0.5rem",
    //   width: "4rem",
    //   height: "4rem",
    //  }
  };
  