import React, { Dispatch, SetStateAction, useState } from 'react';
import { ImageDisplayCON, ImageUploadCON, UploadPictureCON } from './style';
import { CameraPlusIcon } from '../../../../assets/Icons';
import { COMMONTYPES } from '../../../../TYPES/event.types';
import { Button } from '../../Button/Button';
import { useMutationHook } from '../../../../network/mutations/useMutationHook';
import { uploadSellerLogo } from '../../../../network/services/users';
import { useRecoilState } from 'recoil';
import { userAtom } from '../../../../store/atoms/userAtom';

type UploadPictureProps = {
  setOpenUploadSellerLogoModal: Dispatch<SetStateAction<boolean>>;
};

const UploadPicture = ({ setOpenUploadSellerLogoModal }: UploadPictureProps) => {
  const pictureRef = React.useRef<HTMLInputElement>(null);
  const [picture, setPicture] = useState<File | null>(null);
  const [_, setUser] = useRecoilState(userAtom);

  const handleFileRead = (e: COMMONTYPES['inputChange']) => {
    const files = e.target.files;
    files && setPicture(files[0]);
  };

  const { mutateAsync, isLoading } = useMutationHook(uploadSellerLogo, 'UPLOAD_SELLER_LOGO');

  const _handleSellerLogoUpload = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('logo', picture as File);

    const payload = {
      formData
    }

    try {
      const data = await mutateAsync(payload);
      // @ts-ignore
      if (data.status.toLowerCase() === 'success') {
        // set image url to recoil state
        setOpenUploadSellerLogoModal(false);
        setUser((prev: any) => ({ ...prev, profilePicture: data.data }));
      }
    } catch (error: any) {}
  };

  const buttonExtraStyles = {
    width: '100%',
  };

  return (
    <UploadPictureCON>
      {picture && (
        <ImageDisplayCON>
          <img src={URL.createObjectURL(picture)} alt={`uploaded-logo`} />
        </ImageDisplayCON>
      )}
      <ImageUploadCON>
        <div className="image-ref" onClick={() => pictureRef.current?.click()}>
          <CameraPlusIcon />
          <p>Select an Image to upload</p>
        </div>
        <input ref={pictureRef} onChange={handleFileRead} type="file" name="seller-photo" hidden required={true} />
      </ImageUploadCON>

      <Button onClick={_handleSellerLogoUpload} isLoading={isLoading} disabled={isLoading} extraStyles={buttonExtraStyles}>
        Upload
      </Button>
    </UploadPictureCON>
  );
};

export default UploadPicture;
