import styled from '@emotion/styled';
import { COLORS } from '../../../theme/color';
import { Tab, Tabs } from '@mui/material';
import { breakpoints } from '../../../theme/theme';

export const DashboardCON = styled('div')``;

export const TopContentCON = styled('div')`
  color: ${COLORS.MainBlack};
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
  }
  p {
    margin-top: 8px;
    font-size: 18px;
    font-weight: 300;
    line-height: normal;
  }

  button {
    border-radius: 12px;
    background: rgba(47, 77, 196, 0.12);
    flex-shrink: 0;
    width: 222px;
    height: 39px;
    color: #2f4dc4;
    font-size: 14px;
    font-weight: 700;
    margin: 0px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    flex-direction: column;
    align-items: start;
    gap: 32px;
  }
`;

export const DashboardTableCON = styled('div')`
  background: ${COLORS.White};
  border-radius: 12px;
`;

export const DashboardTab = styled(Tab)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '26.483px',
  textTransform: 'capitalize',
  maxWidth: '200px',
  width: '200px',
  color: '#000',
  fontStyle: 'normal',
  '&.Mui-selected': {
    fontSize: '20px',
    fontWeight: '700',
    color: '#000',
    textTransform: 'capitalize',
    backgroundColor: `${COLORS.White}`,
    borderRadius: '23px',

    marginTop: '5px'
  }
}));

export const ActionCON = styled('div')`
  cursor: pointer;
`;

export const DashboardTabs = styled(Tabs)(({ theme }) => ({
  padding: '0px 12px',
  height: '70px',
  gap: '10px',
  minHeight: 'max-content',
  '& .MuiTabs-indicator': {
    height: '2px',
    maxWidth: '100px',
    marginLeft: '6%'
  }
}));

export const ChartsCON = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: 100%;
  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column;
    gap: 0px;
  }
`;