import { DashboardHeader, TopHeaderCard } from '../../components/molecules';

import { columns, deliveryFilterOpts, deliveryRequestColumns, deliveryRequests } from './data';
import { DeliveryCON, DeliveryTableCON, TableHeaderCON } from './style';
import { CustomTable, SelectField, Spacer } from '../../components/atoms';
import { useQuery } from 'react-query';
import { CheckIcon, MessageIcon } from '../../assets/Icons';
import { getSellerDeliveries, getSellerDeliveriesAnalytics } from '../../network/services/deliveries';
import { DELIVERY_STATUS_FILTER_KEYS } from '../../store/keys';
import { useRecoilState } from 'recoil';
import { ChangeEvent, useState } from 'react';
import { deliveryStatusFilter } from '../../store/atoms/deliveryAtom';

export const Delivery = () => {
  const [page, setPage] = useState(1);
  const pageSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE ? parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE) : 10;
  const { data: deliveriesData, isFetchedAfterMount } = useQuery(
    ['seller_deliveries', { page: page - 1, size: pageSize }],
    getSellerDeliveries
  );

  const deliveries = deliveriesData?.data || [];

  const filteredDeliveries = deliveries.filter((delivery: any) => delivery.id != null);

  const { data: analyticsData } = useQuery(['admin_delivery'], getSellerDeliveriesAnalytics);
  const analytics = analyticsData?.data || {};

  const [deliveryStatusState, setDeliveryStatusState] = useRecoilState(deliveryStatusFilter);

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDeliveryStatusState(e.target.value);
  };

  const topCardData = [
    {
      title: 'Delivered',
      total: analytics?.completed,
      Icon: CheckIcon,
      iconBg: '#F5FBEF'
    },
    {
      title: 'Delivery Request',
      total: analytics?.pending,
      Icon: MessageIcon,
      iconBg: '#FFFCF0'
    }
  ];

  const { DELIVERED, DELIVERY_REQUEST } = DELIVERY_STATUS_FILTER_KEYS;

  return (
    <DeliveryCON>
      <DashboardHeader title="Deliveries" />
      <TopHeaderCard gap="60px" data={topCardData} />
      <Spacer height="48px" />
      <DeliveryTableCON>
        <TableHeaderCON>
          {deliveryStatusState.includes(DELIVERED) ? (
            <h3>Delivered</h3>
          ) : (
            deliveryStatusState.includes(DELIVERY_REQUEST) && <h3>Delivery Request</h3>
          )}
          <SelectField handleChange={handleFilterChange} options={deliveryFilterOpts} value={deliveryStatusState} />
        </TableHeaderCON>
        {deliveryStatusState.includes(DELIVERED) ? (
          <CustomTable
            isLoading={isFetchedAfterMount}
            columns={columns}
            data={filteredDeliveries}
            totalItems={deliveriesData?.totalElements ?? 0}
            totalPages={deliveriesData?.totalPage ?? 0}
            currentPage={page}
            setCurrentPage={setPage}
          />
        ) : (
          deliveryStatusState.includes(DELIVERY_REQUEST) && (
            <CustomTable
              columns={deliveryRequestColumns}
              data={deliveryRequests}
              totalItems={deliveriesData?.totalElements ?? 0}
              totalPages={deliveriesData?.totalPage ?? 0}
              currentPage={page}
              setCurrentPage={setPage}
            />
          )
        )}
      </DeliveryTableCON>
    </DeliveryCON>
  );
};
