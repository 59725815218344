import { styled } from '@mui/material';
import { COLORS } from '../../../theme/color';
import { breakpoints, maxWidth } from '../../../theme/theme';

export const FooterCon = styled('div')`
  width: 100%;
  background-color: ${COLORS.MainBlue};
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: max-content;
  flex-direction: column;

  .container {
    max-width: ${maxWidth}px;
    width: 100%;
    padding: 6rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .footerSocial {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: ${breakpoints.md}px) {
      width: 100%;
      padding: 0 1rem;
    }
    .socialLogos {
      display: flex;
      a {
        margin-right: 0.5rem;
        .socialIcon {
          background-color: ${COLORS.White};
          width: 2rem;
          height: 2rem;
          border-radius: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .footerLinks {
    width: 100%;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(13rem, 1fr));
    grid-gap: 2rem;
    align-items: flex-start;

    .gridContainer {
      width: 100%;

      h3 {
        font-weight: 700;
        font-size: 1.125rem;
        color: ${COLORS.White};
      }

      ul {
        list-style-type: none;

        li {
          font-weight: 300;
          font-size: 0.875rem;
          color: rgba(255, 255, 255, 0.9);
          margin-bottom: 1rem;
          cursor: pointer;
          transition: 0.3s ease-in-out;
          text-overflow: ellipsis;
          overflow: hidden;
          &:hover {
            color: ${COLORS.SecBlue};
          }
        }
      }
    }
  }

  p {
    font-weight: 400;
    font-size: 0.875rem;
    color: ${COLORS.White};
  }
`;
