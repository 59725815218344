import { ChangeEvent, useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from 'react-router-dom';
import { HidePassword, ShowPassword, UploadIcon } from '../../../assets/Icons';
import { Button, Container, Spacer } from '../../../components/atoms';
import { InputField } from '../../../components/atoms/InputField/InputField';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { register } from '../../../network/services/auth';
import { validators } from '../../../utils/validator';
import {
  AltSignUp,
  AuthContainer,
  CustomSelect,
  extraStylesInput,
  extraStylesInputPhone,
  InputCon,
  TogglePassword,
  UploadImageCon
} from '../styles';
import { COMMONTYPES } from '../../../TYPES/event.types';
import { getAllcategories } from '../../../network/services/goods';
import { useQuery } from 'react-query';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import SELLER_LOCATIONS from './locations';
import { IRegisterSeller, ResponseSuccess } from '../../../TYPES/api.body';
import CustomMultiSelect from '../../../components/atoms/CustomMultiSelect/CustomMultiSelect';

const INITIALSTATE: IRegisterSeller = {
  request: {
    business_address: '',
    bvn: '',
    business_name: '',
    cac: '',
    categoryNames: [],
    confirm_password: '',
    customer_type: 'NORMAL',
    email: '',
    location: SELLER_LOCATIONS[0],
    nin: '',
    password: '',
    phoneNumber: ''
  },
  government_id: new File([], ''),
  utility_bill: new File([], '')
};

export const SignUp = () => {
  const { data: categoriesData } = useQuery('categories', getAllcategories);
  const categories = categoriesData || [];

  const [formData, setFormData] = useState(INITIALSTATE);
  const [formError, setFormError] = useState(INITIALSTATE);
  const [agreement, setAgreement] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const governmentIdRef = useRef<HTMLInputElement | null>(null);
  const utitlityBiilRef = useRef<HTMLInputElement | null>(null);
  const onToggleAgreement = (e: ChangeEvent<HTMLInputElement>) => {
    setAgreement(e.target.checked);
  };

  const onUpdateFormData = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    const error = validators[name](value);
    setFormData(formData => ({ ...formData, request: { ...formData.request, [name]: value } }));
    setFormError(formError => ({ ...formError, request: { ...formError.request, [name]: error } }));
  };

  const onSelectChange = (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target;
    // if (name === 'categoryNames') {
    //   setFormData(formData => ({ ...formData, request: { ...formData.request, [name]: [value as string] } }));
    //   return;
    // }
    setFormData(formData => ({ ...formData, request: { ...formData.request, [name]: value } }));
  };

  const selectClick = (e: COMMONTYPES['divClick']) => e.stopPropagation();

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState<'text' | 'password'>('password');

  const onTogglePassword = () => {
    if (showPassword === 'text') return setShowPassword('password');
    setShowPassword('text');
  };

  const { mutateAsync, isLoading } = useMutationHook<any, ResponseSuccess>(register, 'SIGNUP');

  const _register = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const payload = new FormData();
    console.log('payload', { ...formData.request, categoryNames: selectedCategories });
    payload.append(
      'request',
      new Blob([JSON.stringify({ ...formData.request, categoryNames: selectedCategories })], {
        type: 'application/json'
      })
    );
    payload.append('utility_bill', formData.utility_bill);
    payload.append('government_id', formData.government_id);

    try {
      const data = await mutateAsync(payload);
      if (data.data.status === 'success') {
        navigate(`/otp/${formData.request.email}`);
      }
    } catch (error) {}
  };

  const handleFileRead = async (e: COMMONTYPES['inputChange']) => {
    const file = e.target.files?.[0];
    const { name } = e.target;

    if (name === 'utility_bill' && file) {
      setFormData({ ...formData, utility_bill: file });
    }
    if (name === 'government_id' && file) {
      setFormData({ ...formData, government_id: file });
    }

    if (file) {
      setFormData(formData => {
        return {
          ...formData,
          [name]: file
        };
      });
    }
  };

  const isUtilityBillUploaded = formData.utility_bill.name !== '';
  const isGovernmentIdUploaded = formData.government_id.name !== '';

  const disabled =
    !formData.request.email ||
    !formData.request.password ||
    !formData.request.phoneNumber ||
    selectedCategories.length === 0 ||
    !!validators.email(formData.request.email) ||
    !!validators.password(formData.request.password) ||
    !isUtilityBillUploaded ||
    !isGovernmentIdUploaded;

  return (
    <AuthContainer>
      <div>
        <h1>Create an account</h1>
        <p className="option">
          Already have an account? <span onClick={() => navigate('/')}>Log in</span>
        </p>
        <Spacer height="2rem" />
        <Container>
          <form className="sign-up">
            <AltSignUp>
              <InputCon>
                <span>Business Name</span>
                <InputField
                  name="business_name"
                  onChange={onUpdateFormData}
                  placeholder="Enter a business name"
                  extraStyles={extraStylesInput}
                  required={true}
                  error={formError.request.business_name}
                />
              </InputCon>
              <Spacer height="2rem" width="2rem" />
              <InputCon>
                <span>Business Address</span>
                <InputField
                  name="business_address"
                  onChange={onUpdateFormData}
                  placeholder="Enter a business address"
                  extraStyles={extraStylesInput}
                  required={true}
                  error={formError.request.business_address}
                />
              </InputCon>
            </AltSignUp>
            <Spacer height="1.5rem" />
            <InputCon>
              <span>Email Address</span>
              <InputField
                type="email"
                name="email"
                onChange={onUpdateFormData}
                placeholder="example@mail.com"
                extraStyles={extraStylesInput}
                required={true}
                error={formError.request.email}
                autoComplete="username"
              />
            </InputCon>
            <Spacer height="1.5rem" />
            <InputCon>
              <span>Phone Number</span>
              <PhoneInput
                country={'ng'}
                value={formData.request.phoneNumber}
                onChange={(value: string) => {
                  setFormData(formData => ({ ...formData, request: { ...formData.request, phoneNumber: value } }));
                }}
                placeholder={'80XXXXXXXX'}
                autoFormat={false}
                inputStyle={extraStylesInputPhone}
                // error={formError.phoneNumber}
              />
            </InputCon>
            <Spacer height="1.5rem" />
            <InputCon>
              <span>CAC Reg Number</span>
              <InputField
                type="text"
                name="cac"
                onChange={onUpdateFormData}
                placeholder="YUGJHGH877"
                extraStyles={extraStylesInput}
                required={true}
                error={formError.request.cac}
              />
            </InputCon>
            <Spacer height="1.5rem" />

            <InputCon>
              <span>Category </span>
              <CustomMultiSelect
                options={categories}
                selectedOptions={selectedCategories}
                setSelectedOptions={setSelectedCategories}
              />
            </InputCon>
            <Spacer height="1.5rem" />

            <InputCon>
              <span>Location </span>
              <CustomSelect
                name="location"
                value={formData.request.location}
                onChange={onSelectChange}
                onClick={selectClick}>
                {SELLER_LOCATIONS.map((location: string, index: number) => (
                  <MenuItem key={location.toLowerCase()} value={location}>
                    {location}
                  </MenuItem>
                ))}
              </CustomSelect>
            </InputCon>
            <Spacer height="1.5rem" />

            <InputCon>
              <span>Password</span>
              <InputField
                type={showPassword}
                name="password"
                onChange={onUpdateFormData}
                placeholder="enter your password"
                extraStyles={extraStylesInput}
                required={true}
                error={formError.request.password}
                action={
                  <TogglePassword onClick={onTogglePassword}>
                    {showPassword !== 'text' ? <ShowPassword /> : <HidePassword />}
                  </TogglePassword>
                }
                autoComplete="new-passowrd"
              />
            </InputCon>

            <Spacer height="1.5rem" />

            <UploadImageCon>
              <span>Utility Bill</span>
              <div onClick={() => utitlityBiilRef.current?.click()}>
                <p>{formData.utility_bill.name || 'Upload Document'}</p>
                <UploadIcon />
                <input
                  type="file"
                  ref={utitlityBiilRef}
                  name="utility_bill"
                  onChange={handleFileRead}
                  required={true}
                />
              </div>
            </UploadImageCon>

            <Spacer height="1.5rem" />

            <Spacer height="1.5rem" />

            <UploadImageCon>
              <span>Government Issued ID</span>
              <div onClick={() => governmentIdRef.current?.click()}>
                <p>{formData.government_id.name || 'Upload Document'}</p>
                <UploadIcon />
                <input
                  type="file"
                  ref={governmentIdRef}
                  name="government_id"
                  onChange={handleFileRead}
                  required={true}
                />
              </div>
            </UploadImageCon>

            <Spacer height="1.5rem" />

            <div className="policy">
              <input type="checkbox" name="policy" id="policy" onChange={onToggleAgreement} />
              <p className="option">
                I have read, understood and I agree to Madina’s<span> Privacy Policy</span>, and{' '}
                <span>Terms and conditions</span>.
              </p>
            </div>

            <div className="buttonContainer">
              <Button
                // extraStyles={bottonStyle}
                isLoading={isLoading}
                type="submit"
                onClick={_register}
                disabled={isLoading || disabled || !agreement}>
                Create Account
              </Button>
            </div>
          </form>
        </Container>
      </div>
    </AuthContainer>
  );
};
