import styled from '@emotion/styled';
import { Accordion, AccordionSummary } from '@mui/material';
import React from 'react';
import { DownArrow } from '../../../assets/Icons';

type customAccordionProps = {
  value: string;
  title: string;
  children: React.ReactNode;
  handleChange: () => void;
};

const StyledAccordion = styled(Accordion)<{ value: string }>`
  background: rgba(47, 77, 196, 0.1);
  box-shadow: none;
  margin: 20px 0;
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  p {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Outfit;
    font-size: 25px;
    font-weight: 400;
    line-height: 39px;
  }
`;



const StyledAccordionDetails = styled(AccordionSummary)``;

export const CustomAccordion = ({ value, handleChange, title, children }: customAccordionProps) => {
  return (
    <StyledAccordion value={value}   onChange={handleChange}>
      <StyledAccordionSummary  expandIcon={<DownArrow />} aria-controls="panel1d-content" id="panel1d-header">
        <p>{title}</p>
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
};
