import { styled } from '@mui/material';
import { COLORS } from '../../../theme/color';

export const List = styled('div')({
  listStyle: 'none',
  display: 'flex',
  fontSize: '0.8rem',
  borderRadius: '10px',
  color: COLORS.MainBlack
});

export const Button = styled('button')({
  width: '2.1rem',
  height: '1.81rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: COLORS.White,
  color: COLORS.MainBlack,
  fontSize: '0.8rem',
  cursor: 'pointer',
  border: '1px solid #EEEEEE',
  borderLeft: 'none'
});

export const ButtonSelected = styled('button')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '2.1rem',
  height: '1.81rem',
  backgroundColor: COLORS.MainBlue,
  color: '#fff',
  fontSize: '0.8rem',
  cursor: 'pointer',
  border: '1px solid #EEEEEE',
  borderLeft: 'none'
});

export const Stylednav = styled('nav')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props: any) => props.theme.breakpoints.down('md')} {
    flex-direction: column;
    align-items: flex-end;
  }
  ${(props: any) => props.theme.breakpoints.down('sm')} {
    align-items: center;
  }
`;
