import { styled } from '@mui/material';
import { Select, MenuItem } from '@mui/material';
import { COLORS } from '../../../theme/color';



export const CustomSelect = styled(Select)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  height: 40px;
  font-size: 14px;
  max-width: 200px;
  border-radius: 8px;
  border-color: #e9e5e5;


 
  
`;

export const CustomMenuItem = styled(MenuItem)`
  color: ${COLORS.MainBlack};
  background: white;
  font-size: 14px;

  &.MuiPaper-root{
    background: white;
  }
`;

export const SelectFieldCON = styled('div')``;
