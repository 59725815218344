import { CheckIcon, MessageIcon } from '../../assets/Icons';
import { Button } from '../../components/atoms';
import { ActionCON, extraButtonStyles } from './style';

export const topCardData = [
  {
    title: 'Confirmed Items',
    total: 3389,
    Icon: CheckIcon,
    iconBg: '#F5FBEF',
    percentage: '+35.5%'
  },
  {
    title: 'Awaiting Confirmation',
    total: 3388,
    Icon: MessageIcon,
    iconBg: '#FFFCF0',
    percentage: '+35.5%'
  }
];

export const filter = [
  {
    label: 'Confirmed Items',
    value: 'Confirmed'
  },

  {
    label: 'Awaiting Items',
    value: 'Awaiting'
  }
];

export const confirmedColumn = [
  {
    id: 1,
    field: 'itemID',
    headerName: 'Item ID',
    flex: 1,
    minWidth: 80
  },

  {
    id: 2,
    field: 'name',
    headerName: 'Seller’s Name',
    flex: 1,
    minWidth: 200
  },

  {
    id: 3,
    field: 'quantity',
    headerName: 'Quantity',
    flex: 1,
    minWidth: 100
  },

  {
    id: 4,
    field: 'transferDate',
    headerName: 'Transfer Date',
    flex: 1,
    minWidth: 100
  },

  {
    id: 4,
    field: 'barcode',
    headerName: 'Barcode',
    flex: 1,
    minWidth: 100
  },

  {
    id: 5,
    field: 'productPrice',
    headerName: 'Product Price',
    flex: 1,
    minWidth: 100
  }
];

export const awaitingFulfilment = [
  {
    id: 1,
    field: 'itemID',
    headerName: 'Item ID',
    flex: 1,
    minWidth: 80
  },

  {
    id: 2,
    field: 'name',
    headerName: 'Seller’s Name',
    flex: 1,
    minWidth: 200
  },

  {
    id: 3,
    field: 'quantity',
    headerName: 'Quantity',
    flex: 1,
    minWidth: 80
  },

  {
    id: 4,
    field: 'transferDate',
    headerName: 'Transfer Date',
    flex: 1,
    minWidth: 100
  },

  {
    id: 5,
    field: 'productPrice',
    headerName: 'Product Price',
    flex: 1,
    minWidth: 100
  },

  {
    id: 4,
    field: 'barcode',
    headerName: 'Barcode',
    flex: 1,
    minWidth: 100
  },

  {
    id: 8,
    field: 'action',
    headerName: 'Action',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => (
      <ActionCON>
        <Button extraStyles={extraButtonStyles}>Confirm</Button>
      </ActionCON>
    )
  }
];

export const fulfilment = [
  {
    id: 1,
    key: 'name',
    noOfItems: 2,
    itemID: '#RA902282',
    name: 'Zara Bag',
    transferDate: '7/03/22, 08:00pm',
    timeTaken: '8hrs 5mins',
    barcode: '615400010700',
    orderType: 'Bulk Order',
    category: 'Fashion',
    quantity: '128',
    productPrice: 'N230,000',
    status: 'Out for delivery'
  },

  {
    id: 2,
    key: 'name',
    name: 'Box of Wine',
    noOfItems: 2,
    barcode: '615400010700',
    timeTaken: '8hrs 5mins',

    itemID: '#RA902282',
    orderType: 'Bulk Order',
    transferDate: '7/03/22, 08:00pm',
    category: 'Supermarket',
    quantity: '128',
    productPrice: 'N230,000',
    status: 'Confirmed',
    flex: 1,
    minWidth: 200
  },

  {
    id: 3,
    key: 'name',
    itemID: '#RA902282',
    name: 'Zara Bag',
    barcode: '615400010700',
    orderType: 'Bulk Order',
    category: 'Fashion',
    timeTaken: '8hrs 5mins',
    quantity: '128',
    transferDate: '7/03/22, 08:00pm',
    noOfItems: 2,
    productPrice: 'N230,000',
    status: 'Processing',
    flex: 1,
    minWidth: 200
  },

  {
    id: 4,
    key: 'name',
    name: 'Zara Bag',
    itemID: '#RA902282',
    noOfItems: 2,
    category: 'Fashion',
    quantity: '128',
    timeTaken: '8hrs 5mins',
    barcode: '615400010700',
    transferDate: '7/03/22, 08:00pm',
    orderType: 'Bulk Order',
    productPrice: 'N230,000',
    status: 'Out for delivery',
    flex: 1,
    minWidth: 200
  }
];
