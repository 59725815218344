import { styled } from '@mui/material';
import { breakpoints } from '../../../theme/theme';

export const OrderCardCon = styled('div')`
  max-width: 675px;
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  margin-bottom: 24px;

  .left {
    flex: 1;
    display: flex;

    img {
      width: 120px;
      height: 120px;
      object-fit: center;
      border-radius: 12px;
      margin-right: 20px;
      flex-shrink: 0;
      @media (max-width: ${breakpoints.lg}px) {
        width: 80px;
        height: 80px;
        border-radius: 8px;
      }
      @media (max-width: ${breakpoints.sm}px) {
        width: 50px;
        height: 50px;
        border-radius: 4px;
      }
    }
    .text {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .text-upper {
        display: grid;
      }
      .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #1e223e;
        margin-bottom: 8px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .id {
        font-weight: 300;
        font-size: 12px;
        line-height: 15px;
        color: #483d3d;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 4px;
      }
      .delivered {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #180101;
      }
    }
  }
  .right {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #180101;
    &:active,
    &:hover,
    &:visited {
      color: #180101;
    }
    margin-left: 20px;
    flex-shrink: 0;
    text-decoration: none;
    @media (max-width: ${breakpoints.sm}px) {
      font-size: 14.5px;
    }
  }
`;
