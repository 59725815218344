import { Tab, Tabs, styled } from '@mui/material';
import { COLORS } from '../../../theme/color';
import { breakpoints } from '../../../theme/theme';

export const SettingCON = styled('div')``;

export const SettingtTableCON = styled('div')`
  background: ${COLORS.White};
`;


export const extraButtonStyles = {
  color: `${COLORS.MainBlue}`,
  backgroundColor: '#EFF1FB',
  borderRadius: '12px',
  height: '31px',
  width: '102px',
  fontSize: '14px',
  fontWeight: '400'
};


export const SettingsRight = styled('div')`
  max-width: 595px;
  background: ${COLORS.White};
  padding: 32px 41px 50px;
  border-radius: 8px;

  h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
    text-align: center;
    margin-bottom: 48px;
  }
  label {
    font-weight: 500;
    font-size: 14px;
    display: inline-block;
    margin-bottom: 8px;
  }
  .name-div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 7.1%;
    @media (max-width: ${breakpoints.sm}px) {
      grid-template-columns: 1fr;
      gap: 32px;
    }
  }
  button {
    height: 56px;
    font-weight: 700;
    font-size: 18px;
  }
  input {
    height: 56px;
    border-radius: 4px;
  }
  input::placeholder {
    font-size: 14px;
  }

  @media (max-width: ${breakpoints.lg}px) {
    min-height: max-content;
    padding: 32px 6% 50px 6%;
  }
`;


export const SettingsTab = styled(Tab)(({ theme }) => ({
  color: ' rgba(0, 0, 0, 0.70)',

  textTransform: 'capitalize',

[theme.breakpoints.up('lg')]: {
  minHeight: '40px',
  height: '40px',
  fontSize: '35px',
  display: 'flex',
  flexDirection: 'row',
  gap: '22px',
  justifyContent: 'start',
  background: 'transparent',
  lineHeight: '24px',
  fontWeight: 600,
  paddingLeft: '6.7%',
  '&.Mui-selected': {
    color: `${COLORS.MainBlack}`
  },

},

}));

export const SettingsTabs = styled(Tabs)(({ theme }) => ({
width: '100%',
minHeight: 'max-content',

}));
