import { MenuItem, SelectChangeEvent } from '@mui/material';
import { Button, InputField, Spacer } from '../../../../components/atoms';
import { Modal } from '../../../../components/molecules';
import { AdduserCon, CustomSelect, InputCon, extraStylesInput } from './style';
import { useState } from 'react';
import { COMMONTYPES } from '../../../../TYPES/event.types';
import { DropdownIcon2 } from '../../../../assets/Icons';
// import { states } from '../../demoData';
import { addNewUser, getLocation, getRoles } from '../../../../network/services/users';
import { useQuery, useQueryClient } from 'react-query';
import { useMutationHook } from '../../../../network/mutations/useMutationHook';
import { validators } from '../../../../utils/validator';

type AddUserprops = {
  onModalClose: () => void;
};

const INITIAL_STATE = {
  email: '',
  sellerRoleId: 1,
  locationId: 7
};

export const AddUser = ({ onModalClose }: AddUserprops) => {
  const [formData, setFormData] = useState(INITIAL_STATE);
  const [formError, setFormError] = useState(INITIAL_STATE);

  const { data } = useQuery('user_roles', getRoles);
  const roles = data || [];
  const { data: locationData } = useQuery('location', getLocation);
  const locationDataSorted = locationData?.sort((a: { name: string; }, b: { name: string; }) => {
    // Handle null and empty strings by converting them to a string and using localeCompare
    const nameA = a.name || ''; // Convert null or undefined to empty string
    const nameB = b.name || ''; // Convert null or undefined to empty string
    return nameA.localeCompare(nameB);
  });
  const locations = locationDataSorted || [];
  const queryClient = useQueryClient();
  const onSelectChange = (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target;
    setFormData(formData => ({ ...formData, [name]: value }));
  };
  const onInputChange = (e: COMMONTYPES['inputChange']) => {
    const { name, value } = e.target;
    const error = validators[name](value);
    setFormData(formData => ({ ...formData, [name]: value }));
    setFormError({
      ...formError,
      [name]: error
    });
  };
  const selectClick = (e: COMMONTYPES['divClick']) => e.stopPropagation();

  const { mutateAsync, isLoading } = useMutationHook(addNewUser, 'ADD_NEW_USER');

  const _createUser = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    try {
      const { data } = await mutateAsync(formData);
      if (data.status === 'success') {
        queryClient.invalidateQueries('seller_users');
        onModalClose();
      }
    } catch (error: any) {}
  };

  const disabled = !!validators.email(formData.email) || !formData.email;
  return (
    <Modal title="Add User" maxWidth="500px" onModalClose={onModalClose}>
      <AdduserCon>
        <InputCon>
          <span>Email</span>
          <InputField extraStyles={extraStylesInput} onChange={onInputChange} name="email" error={formError.email} />
        </InputCon>
        <Spacer height="22px" />
        <InputCon>
          <span>Role </span>
          <CustomSelect
            IconComponent={DropdownIcon2}
            name="sellerRoleId"
            value={formData.sellerRoleId}
            onChange={onSelectChange}
            onClick={selectClick}>
            {roles.map((item: any) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </CustomSelect>
        </InputCon>
        <Spacer height="22px" />
        <InputCon>
          <span>Location </span>
          <CustomSelect
            IconComponent={DropdownIcon2}
            name="locationId"
            label="Please select Locaation"
            value={formData.locationId}
            onChange={onSelectChange}
            onClick={selectClick}>
            {locations.map((item: any) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </CustomSelect>
        </InputCon>

        <Spacer height="52px" />

        <Button onClick={_createUser} isLoading={isLoading} disabled={disabled}>
          Add{' '}
        </Button>
      </AdduserCon>
    </Modal>
  );
};
