import { styled } from '@mui/material';

export const EmptyCon = styled('div')`
  height: 144px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;
