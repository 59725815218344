import { ChangePassword, ResponseSuccess, UpdateCustomer, UpdatePicType } from '../../TYPES/api.body';
import { CHANGE_PASSWORD, DELETE_ACCOUNT, GET_CUSTOMER, UPDATE_CUSTOMER, UPDATE_PIC } from '../config/endpoints';
import api from '../config/setAuthHeader';

export const getCustomer = async () => {
  const { data } = await api.get(GET_CUSTOMER);
  return data;
};

export const updateCustomer = async (formData: UpdateCustomer) => {
  return await api.patch(UPDATE_CUSTOMER, formData);
};

export const changePassword = async (formData: ChangePassword) => {
  const { data } = await api.post(CHANGE_PASSWORD, formData);
  return data;
};

export const updateProfilePic = async (formData: UpdatePicType) => {
  return await api.patch(UPDATE_PIC, formData);
};

export const deleteAccount = async ({ password }: { password: string }): Promise<ResponseSuccess> => {
  const { data } = await api.delete(`${DELETE_ACCOUNT}${password}`);
  return data
};
