import { ILogin, IRegisterSeller, RequestOtp, ResetPasword, VerifyOtp, updatePasword, ConfirmPasswordReset } from '../../TYPES/api.body';
import {
  LOGIN,
  LOGOUT,
  NEW_PASSWORD,
  REGISTER,
  REQUEST_OTP,
  RESET_PASSWORD,
  VERIFY_OTP,
  FORGOT_PASSWORD,
  CONFIRM_PASSWORD_RESET,
} from '../config/endpoints';
import api from '../config/setAuthHeader';

export const login = async (formData: ILogin) => {
  return await api.post(LOGIN, formData);
};

export const logout = async () => {
  return await api.post(LOGOUT);
};

export const register = async (formData: FormData) => {
  return await api.post(REGISTER, formData);
};

export const resetPassword = async (formData: ResetPasword) => {
  return await api.post(RESET_PASSWORD, formData);
};

export const forgotPassword = async (formData: ResetPasword) => {
  return await api.post(FORGOT_PASSWORD, formData);
};

export const confirmPasswordReset = async (formData: ConfirmPasswordReset) => {
  return await api.post(CONFIRM_PASSWORD_RESET, formData);
};

export const newPassword = async (formData: updatePasword) => {
  return await api.post(NEW_PASSWORD, formData);
};

export const verifyOtp = async (payload: VerifyOtp) => {
  return await api.post(VERIFY_OTP, { ...payload });
};

export const requestOtp = async (payload: RequestOtp) => {
  return await api.post(REQUEST_OTP, payload);
};
