import React, { ChangeEvent, useRef, useEffect } from 'react';
import { otpExtraStylesInput } from './style';
import { InputField } from '../InputField/InputField';

interface OtpInputFieldsProps {
  otp: string[];
  setOtp: React.Dispatch<React.SetStateAction<string[]>>;
}

const OtpInputFields = ({ otp, setOtp }: OtpInputFieldsProps) => {
  // Create an array of refs
  const inputRefs = useRef<Array<React.RefObject<HTMLInputElement>>>(otp.map(() => React.createRef<HTMLInputElement>()));

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const newOtp = [...otp];
    const value = e.target.value;

    // Ensure the input is a single digit and numeric
    if (value === '' || (value.length === 1 && /^\d$/.test(value))) {
      newOtp[index] = value;
      setOtp(newOtp);

      // Focus on next input if the value is not empty
      if (value !== '' && index < otp.length - 1) {
        inputRefs.current[index + 1]?.current?.focus();
      }
    }
  };

  // Ensure refs array matches otp array size
  useEffect(() => {
    if (inputRefs.current.length !== otp.length) {
      inputRefs.current = otp.map(() => React.createRef<HTMLInputElement>());
    }
  }, [otp]);

  return (
    <>
      {otp.map((value, index) => (
        <InputField
          key={index}
          extraStyles={otpExtraStylesInput}
          onChange={(e) => handleChange(e, index)}
          name={`otp-${index}`}
          value={value}
          maxLength={1}
          inputRef={inputRefs.current[index]}
        />
      ))}
    </>
  );
};

export default OtpInputFields;