import React, { Dispatch, SetStateAction, useState } from 'react';
// import { useQueryClient } from 'react-query';
import { COMMONTYPES } from '../../../TYPES/event.types';
import { Button, InputField, Spacer } from '../../atoms';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { BankDetails } from '../../../TYPES/api.body';
import { InputCON, InputExtraStyles } from './style';
import { WalletModalCon } from '../../molecules/Wallet/styles';
import { Modal } from '../../molecules';
import { addBankDetails } from '../../../network/services/wallet';

type Props = {
  bankDetails: Omit<BankDetails, 'password'>;
  setConfirmWithdrawModal: Dispatch<SetStateAction<boolean>>;
};

type BankDetailsPassword = Omit<BankDetails, 'bankName' | 'accountName' | 'accountNumber'>;

const INITIAL_STATE: BankDetailsPassword = {
  password: ''
};

function ConfirmBankDetails({ bankDetails, setConfirmWithdrawModal }: Props) {
  const [formData, setFormData] = useState<BankDetailsPassword>(INITIAL_STATE);
  // const queryClient = useQueryClient();
  const onInputChange = (e: COMMONTYPES['inputChange']) => {
    const { name, value } = e.target;
    setFormData(formData => ({ ...formData, [name]: value }));
  };

  const { mutateAsync, isLoading } = useMutationHook(addBankDetails, 'ADD_BANK_DETAILS');

  const _handleAddAccount = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    try {
      const bankDetailsPayload = { ...bankDetails, ...formData };
      const data = await mutateAsync(bankDetailsPayload);

      // @ts-ignore
      if (data.status.toLowerCase() === 'success') {
        onModalClose();
      }
    } catch (error: any) {}
  };

  const onModalClose = () => {
    setConfirmWithdrawModal(false);
  };

  const disabled = !formData.password;

  return (
    <Modal title="Password Confirmation" maxWidth="500px" onModalClose={onModalClose}>
      <WalletModalCon>
        <InputCON>
          <span>Password</span>
          <InputField
            extraStyles={InputExtraStyles}
            name="password"
            type="password"
            placeholder="Enter your password to confirm"
            onChange={onInputChange}
          />
        </InputCON>
        <Spacer height="32px" />

        <Button onClick={_handleAddAccount} isLoading={isLoading} disabled={disabled}>
          Submit{' '}
        </Button>
      </WalletModalCon>
    </Modal>
  );
}

export default ConfirmBankDetails;
