import { styled } from "@mui/material";
import { BasicBox } from "../styles";
import { COLORS } from "../../../theme/color";

export const InboxCon = styled(BasicBox)`
  .list-con {
    h3 {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: ${COLORS.MainBlack};
      margin-bottom: 32px;
    }
  }
`;