import { styled } from '@mui/material';
import { COLORS } from '../../../theme/color';
import { breakpoints } from '../../../theme/theme';

export const ProfileRight = styled('div')`
  max-width: 575px;
  background: ${COLORS.White};
  padding: 32px 41px 50px;
  border-radius: 8px;

  h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
    text-align: center;
    margin-bottom: 48px;
  }
  label {
    font-weight: 500;
    font-size: 14px;
    display: inline-block;
    margin-bottom: 8px;
  }
  .name-div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 7.1%;
    @media (max-width: ${breakpoints.sm}px) {
      grid-template-columns: 1fr;
      gap: 32px;
    }
  }
  button {
    height: 56px;
    font-weight: 700;
    font-size: 18px;
  }
  input {
    height: 56px;
    border-radius: 4px;
  }
  input::placeholder {
    font-size: 14px;
  }

  @media (max-width: ${breakpoints.lg}px) {
    min-height: max-content;
    padding: 32px 6% 50px 6%;
  }
`;
