import { useRecoilState } from 'recoil';
import { Action, BrokenGclose, Edit2Icon } from '../../assets/Icons';
import { CustomPopover } from '../../components/atoms';
import { ActionCON } from '../style';
import { actionPopover } from '../../store/atoms/orderAtom';
import { deleteSubAdmin } from '../../network/services/users';
import { useMutationHook } from '../../network/mutations/useMutationHook';
import { useQueryClient } from 'react-query';
import { useCallback, useState } from 'react';
import { ChangeUserRole } from '../../pages/Seller/User/ChangeRole';

interface SubAdminActionProps {
  row: any;
}

export const SubAdminAction = ({ row }: SubAdminActionProps) => {
  const [value, setValue] = useState<HTMLElement | null>(null);
  const [isUpdateUserRole, setUpdateUserRole] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutationHook(deleteSubAdmin, 'REMOVE_SUB_ADMIN');

  const onUpdateUserModalClose = () => {
    setUpdateUserRole(false);
  };
  const onUpdateUserModalOpen = () => {
    setUpdateUserRole(true);
  };

  const handleOpen = (event: any) => {
    if (value != null) {
      setValue(null);
    } else {
      setValue(event.currentTarget);
    }
  };

  const handleRemoveUser = async (_id: any) => {
    try {
      const data: any = await mutateAsync(_id);
      if (data.status.toLowerCase() === 'success') {
        queryClient.invalidateQueries(['seller_users']);
      }
    } catch (error) {}
  };

  const options = [
    {
      label: 'Remove User',
      value: 'Deactivate Seller',
      Icon: BrokenGclose,
      color: '#D90429',
      handleAction: handleRemoveUser
    },

    {
      label: 'Update Role',
      value: 'Update Role',
      Icon: Edit2Icon,
      color: '#2F4DC4',
      handleAction: onUpdateUserModalOpen
    }
  ];
  return (
    <ActionCON aria-describedby="order-actions" onClick={event => handleOpen(event)}>
      <Action />
      <CustomPopover id="order-actions" open={value} options={options} item={row.id} />
      {isUpdateUserRole && <ChangeUserRole role={row} onModalClose={onUpdateUserModalClose} />}
    </ActionCON>
  );
};
